import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.less']
})
export class LanguageSelectorComponent implements OnInit {

	selectedLang: string = 'en';

	constructor(private translationService: TranslationService, private router: Router, private languageService: LanguageService) {
	}

	ngOnInit() {
		this.setSelectedLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(
				{
					next: event => {
						this.setSelectedLanguage();
					},
					error: (err) => {
						console.error(err);
					}
				}
			);
	}

	changeLang(lang: string) {
		this.selectedLang = lang;
		this.translationService.setLanguage(lang);
		// Emit a language change event
		this.languageService.update(lang);
	}

	setSelectedLanguage(): any {
		this.changeLang(this.translationService.getSelectedLanguage());
	}

}
