import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Operation } from "./operation";

@JsonConverter
export class NotificationSystemConverter implements JsonCustomConvert<NotificationSystem> {
    serialize() {}
    deserialize(data: any): NotificationSystem {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, NotificationSystem);
    }
}

@JsonObject("NotificationSystem")
export class NotificationSystem {
    @JsonProperty("delivery", Boolean, true)
    delivery: boolean = false;
    @JsonProperty("exchange", Boolean, true)
    exchange: boolean = false;
    @JsonProperty("pickup", Boolean, true)
    pickup: boolean = false;
    @JsonProperty("self_deposit", Boolean, true)
    self_deposit: boolean = false;
    @JsonProperty("reservation", Boolean, true)
    reservation: boolean = false;
    @JsonProperty("delegation", Boolean, true)
    delegation: boolean = false;

    setAllTrue(): void {
        this.delivery = true;
        this.exchange = true;
        this.pickup = true;
        this.self_deposit = true;
        //this.reservation = true; //TODO: enable this when reservation will be handled
        this.delegation = true;
    }

    setAllFalse(): void {
        this.delivery = false;
        this.exchange = false;
        this.pickup = false;
        this.self_deposit = false;
        // this.reservation = false; //TODO: enable this when reservation will be handled
        this.delegation = false;
    }

    isEnable(): boolean {
        return this.delivery || this.exchange || this.pickup || this.self_deposit || this.delegation; // || this.reservation; 
    }

    getActivatedEvents(): Set<Operation> {
        const set: Set<Operation> = new Set();
        if(this.delivery) set.add(Operation.DELIVERY);
        if(this.exchange) set.add(Operation.EXCHANGE);
        if(this.pickup) set.add(Operation.PICKUP);
        if(this.self_deposit) set.add(Operation.SELFDEPOSIT);
        // if(this.reservation) set.add(Operation.RESERVATION);
        if(this.delegation) set.add(Operation.DELEGATION);
        return set;
    }

    dto() {
        return {
            delivery: this.delivery,
            exchange: this.exchange,
            pickup: this.pickup,
            self_deposit: this.self_deposit,
            // reservation: this.reservation,
            delegation: this.delegation
        }
    }
}