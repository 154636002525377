import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Location, LocationConverter } from "./location";
import { Resp } from "./resp";

@JsonConverter
export class LockerConverter implements JsonCustomConvert<Locker> {
    serialize() {}
    deserialize(data: any): Locker {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Locker);
    }
}

@JsonConverter
export class LockerArrayConverter implements JsonCustomConvert<Array<Locker>> {
    serialize() { }
    deserialize(data: any): Array<Locker> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Locker);
    }
}

@JsonObject("Locker")
export class Locker {
    @JsonProperty("uuid", String, true)
	uuid: string = "";
    @JsonProperty("name", String, true)
	name: string = "";
    @JsonProperty("vendor", String, true)
	vendor: string = "";
    // @JsonProperty("vendor_locker_uuid", String, true)
	// vendor_locker_uuid: string = "";
    @JsonProperty("model", String, true)
	model: string = "";
    @JsonProperty("location", LocationConverter, true)
	location: Location = new Location();
}

@JsonObject("LockerData")
export class LockerData extends Resp{
    @JsonProperty("data", LockerConverter, true)
	data: Locker = new Locker();
}

@JsonObject("LockerArrayData")
export class LockerArrayData extends Resp{
    @JsonProperty("data", LockerArrayConverter, true)
	data: Array<Locker> = [];
}