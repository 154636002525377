import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../helpers';
import { Account } from '../models/account';

@Injectable({
	providedIn: 'root'
})
export class IdentityService {
	identity$: BehaviorSubject<Account | null> = new BehaviorSubject<Account | null>(new Account());
	
	constructor() {
		this.reset();
	}

	update(identity: Account | null) {
		Helpers.setIdenity(identity);
		this.identity$.next(identity);
	}

	/** Reset data to localStorage values */
	reset() {
		const identity: Account = Helpers.getIdentity()!;
		this.update(identity);
	}
}
