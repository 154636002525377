import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { VersionComponent } from './version/version.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from '../core/modules/ng-zorro/ng-zorro.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PinManagementComponent } from './pin-management/pin-management.component';
import { PinAutoComponent } from './pin-auto/pin-auto.component';
import { ServiceStatusComponent } from './service-status/service-status.component';
import { PreferenceManagementComponent } from './preference-management/preference-management.component';
import { CloudinaryComponent } from './cloudinary/cloudinary.component';
import { SafePipe } from '../core/pipes/safe.pipe';



@NgModule({
  declarations: [
    LoaderComponent,
    LanguageSelectorComponent,
    SpinnerComponent,
    VersionComponent,
    UserProfileComponent,
    PinManagementComponent,
    PinAutoComponent,
    ServiceStatusComponent,
    PreferenceManagementComponent,
    CloudinaryComponent,
    SafePipe,
  ],
  imports: [
    CommonModule,
    NgZorroModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoaderComponent,
    LanguageSelectorComponent,
    SpinnerComponent,
    VersionComponent,
    UserProfileComponent,
    PinManagementComponent,
    PinAutoComponent,
    ServiceStatusComponent,
    PreferenceManagementComponent,
    CloudinaryComponent,
    SafePipe
  ],
})
export class LayoutModule { }
