import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty, Any } from "json2typescript";
import { UserStatus, UserStatusConverter } from "./account-status";
import { Gender, GenderConverter } from "./gender";
import { NotificationSettings, NotificationSettingsConverter } from "./notification-settings";
import { Resp } from "./resp";

@JsonConverter
export class AccountConverter implements JsonCustomConvert<Account> {
    serialize() {}
    deserialize(data: any): Account {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Account);
    }
}

@JsonObject("Account")
export class Account {
    @JsonProperty("uuid", String, false)
	uuid: string = "";
    @JsonProperty("email", String, false)
	email: string = "";
    @JsonProperty("email_verified", Boolean, true)
	email_verified: boolean = false;
    @JsonProperty("first_name", String, true)
	first_name: string = "";
    @JsonProperty("middle_name", String, true)
	middle_name: string | null = null;
    @JsonProperty("last_name", String, true)
	last_name: string = "";
    @JsonProperty("avatar", String, true)
	avatar: string = "";
    @JsonProperty("gender", GenderConverter, true)
	gender: Gender = Gender.OTHER;
    @JsonProperty("phone_number", String, true)
	phone_number: string | null = null;
    @JsonProperty("preferred_language", String, true)
    preferred_language: string = '';
    @JsonProperty("notification_settings", NotificationSettingsConverter, true)
    notification_settings: NotificationSettings = new NotificationSettings;
    @JsonProperty("status", UserStatusConverter, true)
	status: UserStatus = UserStatus.SUSPENDED;

    /**
     * Complete dto
     * @param addUuid 
     * @returns 
     */
    dtoAll(addUuid: boolean = false) {
        const request: any = {
            email: this.email,
            email_verified: this.email_verified,
            first_name: this.first_name,
            middle_name: this.middle_name,
            last_name: this.last_name,
            avatar: this.avatar,
            gender: this.gender,
            status: this.status,
            preferred_language: this.preferred_language,
            notification_settings: this.notification_settings.dto(),
            phone_number: this.phone_number
        };
        if (addUuid) {
            request['uuid'] = this.uuid;
        }
        return request;
    }

    /**
     * Request dto
     * @param addUuid 
     * @returns 
     */
    dto(addUuid: boolean = false, addEmail: boolean = false) {
        const request: any = {
            first_name: this.first_name,
            middle_name: this.middle_name,
            last_name: this.last_name,
            avatar: this.avatar,
            gender: this.gender,
            preferred_language: this.preferred_language,
            notification_settings: this.notification_settings.dto(),
            phone_number: this.phone_number
        };
        if (addUuid) {
            request['uuid'] = this.uuid;
        }
        if (addEmail) {
            request['email'] = this.email;
        }
        return request;
        
    }
}

@JsonObject("AccountData")
export class AccountData extends Resp {
    @JsonProperty("data", AccountConverter, true)
	data: Account = new Account();
}