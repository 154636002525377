import { Injectable } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { Helpers } from '../helpers';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {

	constructor(private nzConfigService: NzConfigService) { }

	setConfiguration() {
		const apparence = Helpers.getDashboardConfig();
		this.nzConfigService.set('theme',
			{
				primaryColor: apparence?.primary_color_accent,
				successColor: apparence?.success_color,

			}
		);
		// For text color
		if (document.getElementById('customRootVariable')) {
			this.deleteStyle();
		}
		const head = document.head || document.getElementsByTagName('head')[0],
			style: any = document.createElement('style');
		style.id = 'customRootVariable';
		const css: string = `:root {
			--ant-btn-primary-color: ${apparence?.secondary_color_accent || '#fff'}
		}`
		head.appendChild(style);

		style.type = 'text/css';
		if (style.styleSheet) { // This is required for IE8 and below.
			style.styleSheet.cssText = css;
		} else {
			style.appendChild(document.createTextNode(css));
		}

		// For side bar logo background color
		const elements: HTMLCollectionOf<Element> = document.getElementsByClassName("sidebar-logo");
		for (var i = 0; i < elements.length; i++) {
			elements[i].setAttribute('style', 'background-color: ' + apparence?.tertiary_color_accent);
		}
	}

	deleteStyle(): void {
		document.getElementById('customRootVariable')?.remove();
	}
}
