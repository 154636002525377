import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable, throwError } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account, AccountData } from '../../models/account';
import { Locker, LockerArrayData } from '../../models/locker';
import { HttpUtilsService } from '../http-utils.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    readonly endpoint: string = environment.baseUrl;

    constructor(private http: HttpClient, 
        private httpUtils: HttpUtilsService) { }

    /**
     * Update user data
     * @param user 
     * @returns 
     */
    updateUser(user: Account): Observable<Account> {
        const body = user.dto();
        return this.httpUtils.getHTTPHeadersAsync().pipe(
            mergeMap(httpHeaders => {
                return this.http.put(`${this.endpoint}/users/${user.uuid}`, JSON.stringify(body), { headers: httpHeaders })
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: AccountData = jsonConvert.deserializeObject(res, AccountData);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return new Account();
                            }
                        }),
                        catchError(err => {
                            if (err.status === 401) { // Unauthorized
                                this.httpUtils.logout();
                            }
                            return throwError(err);
                        })
                    );
            })
        );
    }

    /**
     * Get passed user uuid lockers list
     * @param userUuid 
     * @returns 
     */
    getUserLockers(userUuid: string): Observable<Array<Locker>> {
        return this.httpUtils.getHTTPHeadersAsync().pipe(
            mergeMap(httpHeaders => {
                return this.http.get(`${this.endpoint}/users/${userUuid}/lockers`, { headers: httpHeaders })
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: LockerArrayData = jsonConvert.deserializeObject(res, LockerArrayData);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return [];
                            }
                        }),
                        catchError(err => {
                            if (err.status === 401) { // Unauthorized
                                this.httpUtils.logout();
                            }
                            return throwError(err);
                        })
                    );
            })
        );
    }
}