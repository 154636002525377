<p>{{'wizard.lockerSteps.manualGenerationPin' | translate}}</p>
<p>{{'wizard.lockerSteps.manualGenerationPinAlternative' | translate}}</p>
<div class="container-fluid">
    <!-- Master -->
    <ng-container *ngIf="isSupporterdCode(codeType.MASTER)">
        <ng-container *ngIf="!masterCode.code;else masterTpl">
            <div class="row">
                <div class="col-6">
                    <button class="generate-pin-button" nz-button nzType="text" (click)="generatePin(masterCode)" [nzLoading]="generateLoading">
                        <span nz-icon nzType="reload" nzTheme="outline"></span>
                        {{'pinManagement.generatePin' | translate}} {{'pinManagement.master' | translate}}
                    </button>
                </div>
                <input class="pin-input col-4" nz-input [(ngModel)]="masterCode.code" />     
            </div>
        </ng-container>
        <ng-template #masterTpl>
            <p class="fw-bold mb-0">
                <span><img style="width: 24px;" src="assets/images/key.png"></span>
                {{'pinManagement.master' | translate}}: <span>{{masterCode.code}}</span></p>
            <p class="text-muted">{{'pinManagement.masterPinDescription' | translate}}</p>
        </ng-template>
    </ng-container>
    <!-- Delivery B2C -->
    <ng-container *ngIf="isSupporterdCode(codeType.B2C) && deliveryCode.locker_uuid">
        <ng-container *ngIf="!deliveryCode.code;else deliveryTpl">
            <div class="row mt-1">
                <div class="col-6">
                    <button class="generate-pin-button" nz-button nzType="text" (click)="generatePin(deliveryCode, reservationType.DELIVERY)" [nzLoading]="generateLoading">
                        <span nz-icon nzType="reload" nzTheme="outline"></span>
                        {{'pinManagement.generatePin' | translate}} {{'pinManagement.delivery' | translate}}
                    </button>
                </div>
                <input class="pin-input col-4" nz-input [(ngModel)]="deliveryCode.code" />     
            </div>
        </ng-container>
        <ng-template #deliveryTpl>
            <p class="fw-bold mb-0">
                <span><img style="width: 24px;" src="assets/images/outline_delivery.png"></span>
                {{'pinManagement.delivery' | translate}}: <span>{{deliveryCode.code}}</span></p>
            <p class="text-muted">{{'pinManagement.deliveriesPinDescription' | translate}}</p>
        </ng-template>
    </ng-container>
    <!-- Return B2C -->
    <ng-container *ngIf="isSupporterdCode(codeType.B2C) && returnCode.locker_uuid">
        <ng-container *ngIf="!returnCode.code;else returnTpl">
            <div class="row mt-1">
                <div class="col-6">
                    <button class="generate-pin-button" nz-button nzType="text" (click)="generatePin(returnCode, reservationType.RETURN)" [nzLoading]="generateLoading">
                        <span nz-icon nzType="reload" nzTheme="outline"></span>
                        {{'pinManagement.generatePin' | translate}} {{'pinManagement.pickup' | translate}}
                    </button>
                </div>
                <input class="pin-input col-4" nz-input [(ngModel)]="returnCode.code" />     
            </div>
        </ng-container>
        <ng-template #returnTpl>
            <p class="fw-bold mb-0">
                <span><img style="width: 24px;" src="assets/images/settings_backup_restore.png"></span>
                {{'pinManagement.pickup' | translate}}: <span>{{returnCode.code}}</span></p>
            <p class="text-muted">{{'pinManagement.returnPinDescription' | translate}}</p>
        </ng-template>
    </ng-container>
    <!-- External -->
    <ng-container *ngIf="isSupporterdCode(codeType.EXTERNAL)">
        <ng-container *ngIf="!externalCode.code;else externalTpl">
            <div class="row mt-1">
                <div class="col-6">
                    <button class="generate-pin-button" nz-button nzType="text" (click)="generatePin(externalCode)" [nzLoading]="generateLoading">
                        <span nz-icon nzType="reload" nzTheme="outline"></span>
                        {{'pinManagement.generatePin' | translate}} {{'pinManagement.external' | translate}}
                    </button>
                </div>
                <input class="pin-input col-4" nz-input [(ngModel)]="externalCode.code" />     
            </div>
        </ng-container>
        <ng-template #externalTpl>
            <p class="fw-bold mb-0">
                <span><img style="width: 24px;" src="assets/images/external.png"></span>
                {{'pinManagement.external' | translate}}: <span>{{externalCode.code}}</span></p>
            <p class="text-muted">{{'pinManagement.externalPinDescription' | translate}}</p>
        </ng-template>
    </ng-container>
    <!-- TODO: controllare questo layout -->
    <ng-container *ngIf="otherCodes.length > 0">
        <p>{{'pinManagement.yourOldPinsTitle' | translate}}</p>
        <p>{{'pinManagement.yourOldPinsDescription' | translate}}</p>
        <div *ngFor="let code of otherCodes">
            <p>{{code.code}}</p>
            <p class="text-muted">{{code.description}}</p>
        </div>
        <br>
    </ng-container>
</div>
