import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import * as moment from "moment";
import { Box } from "./box";
import { Resp } from "./resp";

@JsonConverter
export class ReservationBoxArrayConverter implements JsonCustomConvert<Array<ReservationBox>> {
    serialize(){}
    deserialize(data: any): Array<ReservationBox>{
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, ReservationBox);
    }
}

@JsonConverter
export class ReservationArrayConverter implements JsonCustomConvert<Array<Reservation>> {
    serialize(){}
    deserialize(data: any): Array<Reservation>{
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Reservation);
    }
}

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date | null> {
    serialize(){}
    deserialize(data: any): Date | null{
		if (data) {
			return moment(data).toDate();
		} else {
			return null;
		}
    }
}

@JsonObject("ReservationBox")
export class ReservationBox extends Box {
    @JsonProperty("reservation_uuid", String, true)
	reservation_uuid: string = "";
    @JsonProperty("valid_until", DateConverter, true)
	valid_until: Date | null = null;
}

@JsonObject("Reservation")
export class Reservation {
    @JsonProperty("locker_uuid", String, true)
	locker_uuid: string = "";
    @JsonProperty("boxes", ReservationBoxArrayConverter, true)
	boxes: Array<ReservationBox> = [];
}


@JsonObject("ReservationData")
export class ReservationData extends Resp {
    @JsonProperty("data", ReservationArrayConverter, true)
	data: Array<Reservation> = [];
}