import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";


@JsonConverter
export class BoxArrayConverter implements JsonCustomConvert<Array<Box>> {
    serialize() { }
    deserialize(data: any): Array<Box> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Box);
    }
}

@JsonObject("Box")
export class Box {
    @JsonProperty("box_uuid", String, true)
    box_uuid: string = "";
    @JsonProperty("box_name", String, true)
    box_name: string = "";
    @JsonProperty("box_size", String, true)
    box_size: string = "";
}