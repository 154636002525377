export const locale = {
	lang: 'en',
	data: {
		login: {
			forgotPassword: 'Forgot password?',
			firstAccess: 'First access? Click here',
			moreTenants: 'Your account is associated with multiple environments.',
			selectOneTenant: 'Select one of them to log in:',
			errors: {
				email: 'Please input a valid email',
				password: 'Please input your Password'
			}
		},
		firstAccess: {
			title: 'Create your password',
			email: 'Enter your email',
			description: 'Enter your email address. We\'ll send you an email to create your password.',
			button: 'Send',
			back: 'Back to login page',
		},
		forgotPassword: {
			title: 'Restore your password',
			email: 'Enter your email',
			description: 'Enter your email address. We\'ll send you an email to rest your password.',
			button: 'Send',
			back: 'Back to login page',
		},
		authentication: {
			sign_in: 'Sign in',
			register: 'Register',
			signing_in: 'Signing in',
			forgot_password: 'Forgot password?',
			registration: 'Registration',
			reset_password: 'Reset e-mail address to password',
			registration_completed: 'Registration completed',
			emailConfirmationText: 'We have send a verification email, check your inbox and click on the link and confirm your e-mail address.',
			emailConfirmationTitle: 'Send a confirmation e-mail',
			resetSuccess: 'An email with a reset password link is sent successfully. Follow the instructions for reset the password',
			confirm_password: 'Confirm password',
			tos: 'I agree to the Terms of Service and Conditions',
			privacy: 'I have read and agree to the Privacy',
			error: 'Error',
			google_sign_in: 'Sign in with Google',
			firstAccess: 'First access? Click here',
			firstAccessTitle: 'Set your password',
			new_password: 'Enter your email',
			emailSent: 'Email sent successfully',
			newUserSuccess: 'An email with a setup password link is sent successfully. Follow the instructions for setup the password',
			errors: {
				generic: "An error has occurred, please try again later",
				user_not_found: "There is no user record corresponding to this identifier or the user may have been deleted.",
				user_disabled: 'The given email has been disabled.',
				invalid_email: 'The email address is not valid.',
				wrong_password: 'The password is invalid for the given email, or the account corresponding to the email does not have a password set.',
				account_exists_with_different_credential: 'Already exists an account with the given email address',
				popup_blocked: 'The popup was blocked by the browser, please enable popups for this website.',
				email_already_in_use: 'Already exists an account with the given email address',
				weak_password: 'The password is not strong enough',
				tos: 'You must first agree to the Terms of Service and Conditions',
				privacy: 'You must first agree to the Privacy',
				// email_not_verified: 'It seems you have not verified your email address. Please check your inbox.'
				email_not_verified: 'Your email address has not yet been verified. Click on the verification link in the email that has been sent to your address.',
				unauthorized: 'Access is unauthorized',
				missingTenants: 'Missing tenants configuration',
				missingDashboardConfiguration: 'Missing dashboard configuration',
				activateAccount: 'Account is not active yet. Please check that you have received the email containing the activation link.',
				accountSuspended: 'The account is suspended. Please contact your administrator for more information.',
				accountDeleted: 'Account is deleted. Please contact your administrator for more information.',
			}
		},
		general: {
			pageNotFound: 'Page not found',
			unauthorized: 'You are unauthorized',
			spinnerMessage: 'Pagin loading',
			loaderMessage: 'Loading data',
			lastLogin: 'Last access',
			nextButton: 'Next',
			preButton: 'Back',
			goToHome: 'Go to home',
			save: 'Save',
			cancel: 'Cancel',
			image: 'Image',
			add: 'Add',
			error: 'Error',
			proceed: 'OK',
			email: 'Insert email address',
			required: 'Required field',
			selectedLocker: 'Selected locker',
			confirm: 'Confirm',
			downloadApp: 'Download the app',
		},
		cloudinary: {
			howToLoad: 'Select how to upload image',
			addImage: 'Add%20an%20image',
			addNewImage: 'Add an image',
			fromUrlText: 'Image from URL',
			fromFolderText: 'Image from Folder',
			remoteUrl: 'Enter the remote URL',
			chooseFile: 'Choose a file',
			removeImage:'Remove image',
            selectImage: 'Choose an image...',
            selctFromList: 'Or select from list',
            useThisImage: 'Use this image',
            deleteImage: 'Delete this image',
			email: 'Enter an email address',
			limits: {
				msg1: 'Image with must be ',
				msg2: ' and height must be ',
			},
			errors: {
				emptyURL: 'Please enter an valid URL',
				upload: 'An error has occurred, please try again',
				limits: `Image size is wrong, width: %%%px, height: ###px`,
			}
		},
		serviceStatus: {
			deliveries: 'deliveries',
			delivery: 'delivery',
			DELIVERY: 'Delivery',
			noDeliveries: 'There are no deliveries',
			oneDelivery: 'There are one delivery for you',

			returns: 'returns',
			return: 'return',
			RETURN: 'Return',
			noReturns: 'There are no returns',
			oneReturn: 'There is a return awaiting collection',
			awaitingCollection: 'awaiting collection',

			selfDeposits: 'self deposits',
			selfDeposit: 'self deposit',

			exchanges: 'exchanges',
			exchange: 'exchange',

			noReservations: 'There are no reservations',
			oneReservation: 'There is one reservation',
			reservations: 'reservations',
			reservation: 'reservation',

			delegations: 'delegations',
			delegation: 'delegation',
			noDelegations: 'There are no delegations',
			oneDelegation: 'There is one delegation',

			thereAre: 'There are',
			thereIs: 'There is',
			inLocker: 'in locker',

			insertedByYou: 'inserted by you in locker',
			insertedByYouPlural: 'inserted by you in locker',
			confirmDeleteReservation: 'Do you confirm to delete this reservation?',
			reservationDeleted: 'Reservation deleted successfully',
			confirmDeleteDelegation: 'Do you confirm to delete this delegation?',
			createDelegation: 'Create new delegation',
			createDelegationSuccess: 'Delegation successfully created',
			createReservation: 'Create new reservation',
			noFreeBoxes: 'No free boxes available',
			freeBoxes: 'Free boxes',
			freeBoxesTip: 'Select a free box',
			reservationType: 'Reservation type',
			reservationTypeTip: 'Select a reservation type',
			createReservationSuccess: 'Reservation successfully created',
			delegationDeleted: 'Reservation successfully deleted',
			errors: {
				unableToGetReservations: 'Unable to get reservations list',
				unableToGetDelegations: 'Unable to get delegations list',
				unableToDeleteReservation: 'Unable to delete the reservation',
				unableToDeleteDelegation: 'Unable to delete the delegation',
				unableToCreateDelegation: 'Unable to create a new delegation',
				unableToCreateDelegationUserNotExist: 'Unable to create a new delegation, user does not exist.',
				unableToCreateDelegationUserAlreadyExist: 'Unable to create a new delegation. The user is already present in your delegations.',
				unableToGetFreeBoxes: 'Unable to get locker free boxes list',
				unableToCreateReservation: 'Unable to create a new reservation',
			}
		},
		menu: {
			account: 'Account',
			home: 'Home',
			lockers: 'Lockers'
		},
		home: {
			welcome: 'Welcome to Smart Locker',
			noPendingServices: 'No pending operations',
			message: 'These are the operations you have pending on your lockers',
			errors: {
				unableToGetPendingServices: 'Unable to get pending operations',
			}
		},
		account: {
			title: 'Account',
			personalDataUpdating: 'Personal data updating',
			changePassword: 'Change password',
			deleteAccount: 'Delete account',
			viewTermsAndconditions: 'View \'Terms e conditions\'',
			accountForm: {
				firstname: 'Firstname',
				lastname: 'Lastname',
				middleName: 'Middle name',
				gender: 'Gender',
				phoneNumber: 'Phone number'
			},
			genders: {
				FEMALE: 'Female',
				MALE: 'Male',
				OTHER: 'Other'
			},
			successes: {
				updatedAccount: 'Account updated successfully',
				updateNotification: 'Your notification\'s preferences updated successfully'
			},
			errors: {
				unableToUpdateAccount: 'Unable to update account',
				unableToGetAccount: 'Unable to get your account data',
				unableToUpdateNotification: 'Unable to update your notification\'s preferences'
			}
		},
		lockers: {
			preferenceManagement: 'Notifications management',
			selectNotificationSystem: 'Select a notification system',
			warning: 'It\'s possible to choose between notification systems made available by the locker administrator.',
			pendingServices: 'Pending operations',
			noPendings: 'No pending operations on this locker',
			operations: {
				name: 'Operations',
			},
			notificationSystem: {
				name: 'Notification system',
				email: 'Email',
				push: 'Push'
			},
			errors: {
				unableToGetLockers: 'Unable to get locker\'s list'
			}
		},
		wizard: {
			tcStep: {
				title: 'Terms and conditions',
				subtitle: 'Read and accept our Terms and conditions to proceed.',
				acceptTc: 'I declare that I have read and accepted \'Terms and conditions\'.',
				noLink: 'Unable to get \'Terms and condiction\''
			},
			accountStep: {
				name: 'Personal data',
				welcome: 'Welcome to SmartLocker,',
				whatIsSmartLocker: 'the system that allows you to pick up and send your packages when you want, without wating courier or risk missing a delivery.',
				description: 'First of all check your personal data and start taking advantage of the all features available.',
			},
			lockerSteps: {
				name: 'Lockers Management',
				associatedLockers: 'Associated lockers',
				lockerConfiguration: 'Locker configuration',
				autoGenerationPin: 'This locker provides automatic generation of PINs, both for personal authentication and the one for deliveries, which is to be shared with logistics companies to be able to insert packages addressed to you.',
				manualGenerationPin: 'This locker provides the ability to choose your access and delivery PINs. To do this, enter them and click on "Next".',
				manualGenerationPinAlternative: 'Otherwise, the system can generate them for you. Press the two generation buttons and click on "Next".',
				lockersDetected: 'Detected lockers associated to your account:',
				description: 'The next steps will allow you to authenticate on devices, receive deliveries and make returns.',
				manageYourPins: 'You can manage them from the specific lockers section.',
				configurationFor: 'Configuration for:'
			},
			notificationsStep: {
				name: 'Notifications',
				pushMessage: 'If you want to receive instant notifications about a package delivery or a return made in the locker, you can activate push notifications from the "Account" section of the app.',
				emailMessage: 'If you prefer, you can receive noifications about package deliveries, returns and others operation made on locker by email.',
				emailActiveMessage: 'Click on "Enable email notifications" to enable them.',
				emailFinalMessage: 'You can manage these configurations from the "Account" section of this dashboard or by app.',
				enableButton: 'Enable email notifications',
				notEnableButton: 'Not enable',
				successes: {
					enableUpdate: 'Email notifications enabled successfully',
					disableUpdate: 'Email notifications disabled successfully'
				},
				errors: {
					unableToUpdateEmailNotifications: 'Unable to update email notifications'
				}
			},
			finalStep: {
				name: 'Done',
				message: 'Everything is ready to use the locker.',
				messageGoToHome: 'Click on the "Go to home" button to get started.',		
			}
		},
		pinManagement: {
			yourPins: 'Your associated PINs:',
			yourOldPinsTitle: 'Other PINs',
			yourOldPinsDescription: 'These are your PINs already configured and associated with this locker:',
			noPin: 'No PIN',
			noDescription: 'No description',
			master: 'Master',
			delivery: 'Delivery',
			deliveryB2C: 'Delivery B2C',
			deliveryB2B: 'Delivery B2B',
			pickup: 'Return',
			external: 'External',
			pinCreationLoading: 'Pins creation...',
			generatePin: 'Generate pin',
			updateMaster: 'Regenerate master pin', 
			updateDelivery: 'Regenerate delivery pin', 
			updateReturn: 'Regenerate reuturn pin',
			updateExternal: 'Regenerate external pin', 
			updateConfirmMessage: 'Warning: regenerating this PIN you will immediately make the previous one unusable.', 
			updateConfirmRequest: 'Do you want to proceed?',
			managePins: 'Pins management',
			masterPinDescription: 'For direct access to the locker via screen',
			deliveriesPinDescription: 'Provide this pin to logistics services to make deliveries',
			deliveriesB2CDescription: 'These pins are for your personal purchases deliveries',
			deliveriesB2BDescription: 'These pins are for corporate order deliveries',
			returnPinDescription: 'Provide this pin to logistics services to make returns',
			externalPinDescription: 'This pin is for order deliveries and to provide to logistics services to make returns',
			errors:{
				unableToGetCodes: 'Unable to get pins for this locker',
				unableToGenerateCode: 'Unable to generate pin'
			}
		},
		public: {
			goToHome: 'Go to login page',
			emailVerified: {
				title: 'Your email has been successfully verified',
				subTitle: 'Click on the button below to return to the login page',
				donwloadApp: 'Alternatively download the Smart Locker app',
			},
			emailNotFound: {
				title: 'Your email could not be verified',
				subTitle: 'The link to verify your email has expired. Please repeat the procedure',
			},
			emailError: {
				title: 'Your email could not be verified',
				subTitle: 'An error occurred while verifying your email address. Please repeat the procedure',
			}
		}
	}
}