import { TranslateService } from "@ngx-translate/core";
import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Locker, LockerConverter } from "./locker";
import { Resp } from "./resp";

@JsonConverter
export class ServiceStatusConverter implements JsonCustomConvert<ServiceStatus> {
    serialize() {}
    deserialize(data: any): ServiceStatus {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, ServiceStatus);
    }
}

@JsonConverter
export class ServiceStatusArrayConverter implements JsonCustomConvert<Array<ServiceStatus>> {
    serialize() { }
    deserialize(data: any): Array<ServiceStatus> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, ServiceStatus);
    }
}

@JsonObject("ServiceStatus")
export class ServiceStatus {
    @JsonProperty("locker", LockerConverter, true)
	locker: Locker = new Locker;
    @JsonProperty("locker_name", String, true)
	locker_name: string = "";
    @JsonProperty("deliveries", Number, true)
	deliveries: number = 0;
    @JsonProperty("pickups", Number, true)
	pickups: number = 0;
    @JsonProperty("self_deposits", Number, true)
	self_deposits: number = 0;
    @JsonProperty("exchanges", Number, true)
	exchanges: number = 0;
    @JsonProperty("reservations", Number, true)
	reservations: number = 0;
    @JsonProperty("delegations", Number, true)
	delegations: number = 0;

	/**
	 * Get the delivery message
	 * @param translate 
	 * @returns an array of strings to create message to show
	 */
    getDeliveriesMessage(translate: TranslateService, isHome: boolean): Array<string> {
		if(this.deliveries === 0){
			return isHome ? 
				[`${translate.instant('serviceStatus.noDeliveries')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] : 
				[translate.instant('serviceStatus.noDeliveries')];
		}else if (this.deliveries === 1){
			return isHome ?  
				[`${translate.instant('serviceStatus.oneDelivery')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] :
				[translate.instant('serviceStatus.oneDelivery')];
		}else {
			return isHome ?  
				[`${translate.instant('serviceStatus.thereAre')} ${this.deliveries} ${translate.instant('serviceStatus.deliveries')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] :
				[`${translate.instant('serviceStatus.thereAre')} ${this.deliveries} ${translate.instant('serviceStatus.deliveries')}`];
		}
    }

	/**
	 * Get the return message
	 * @param translate 
	 * @returns an array of strings to create message to show
	 */
    getReturnsMessage(translate: TranslateService, isHome: boolean): Array<string> {
		if(this.pickups === 0){
			return isHome ? 
				[`${translate.instant('serviceStatus.noReturns')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] : 
				[translate.instant('serviceStatus.noReturns')];
		}else if (this.pickups === 1){
			return isHome ?  
				[`${translate.instant('serviceStatus.oneReturn')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] :
				[translate.instant('serviceStatus.oneReturn')];
		}else {
			return isHome ?  
				[`${translate.instant('serviceStatus.thereAre')} ${this.pickups} ${translate.instant('serviceStatus.returns')} ${translate.instant('serviceStatus.awaitingCollection')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] :
				[`${translate.instant('serviceStatus.thereAre')} ${this.pickups} ${translate.instant('serviceStatus.returns')} ${translate.instant('serviceStatus.awaitingCollection')}`];
		}
    }

	/**
	 * Get the self deposit message
	 * @param translate 
	 * @returns a string to show as the message for self-deposit
	 */
	getSelfDepositMessage(translate: TranslateService): string {
		const result: Array<string> = [];
		if (this.self_deposits != 1) {
			result.push(translate.instant('serviceStatus.thereAre'));
		} else {
			result.push(translate.instant('serviceStatus.thereIs'));
		}
		result.push(this.self_deposits.toString());
		if (this.self_deposits != 1) {
			result.push(translate.instant('serviceStatus.selfDeposits'));
		} else {
			result.push(translate.instant('serviceStatus.selfDeposit'));
		}
		result.push(translate.instant('serviceStatus.inLocker'));
		result.push(this.locker.name);
		return result.join(" ");
	}

	/**
	 * Get the exchange message
	 * @param translate 
	 * @returns a string to show as the message for exchanges
	 */
	getExchangesMessage(translate: TranslateService): string {
		const result: Array<string> = [];
		if (this.exchanges != 1) {
			result.push(translate.instant('serviceStatus.thereAre'));
		} else {
			result.push(translate.instant('serviceStatus.thereIs'));
		}
		result.push(this.exchanges.toString());
		if (this.exchanges != 1) {
			result.push(translate.instant('serviceStatus.exchanges'));
		} else {
			result.push(translate.instant('serviceStatus.exchange'));
		}
		result.push(translate.instant('serviceStatus.inLocker'));
		result.push(this.locker.name);
		return result.join(" ");
	}

	/**
	 * Get the reservation message
	 * @param translate 
	 * @returns a map with two elements: "main": the main massage and "name": the locker name
	 */
	getReservationsMessage(translate: TranslateService, isHome: boolean): Map<string, string> {
		const main: Array<string> = [];
		const result: Map<string, string> = new Map;
		if (this.reservations == 0) {
			main.push(translate.instant('serviceStatus.noReservations'));
		} else {
			if (this.reservations == 1) {
				main.push(translate.instant('serviceStatus.oneReservation'));
			} else {
				main.push(translate.instant('serviceStatus.thereAre'));
				main.push(this.reservations.toString());
				main.push(translate.instant('serviceStatus.reservations'));
			}
		}
		if(isHome){
			main.push(translate.instant('serviceStatus.inLocker'));
			result.set("main", main.join(" "));
			result.set("name", this.locker.name);		
		}else {
			result.set("main", main.join(" "));
			result.set("name", '');
		}
		return result;
	}

	/**
	 * Get the delagation message
	 * @param translate 
	 * @returns an array of strings to create message to show
	 */
	getDelegationsMessage(translate: TranslateService, isHome: boolean): Array<string> {
		if(this.delegations === 0){
			return isHome ? 
				[`${translate.instant('serviceStatus.noDelegations')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] : 
				[translate.instant('serviceStatus.noDelegations')];
		}else if (this.delegations === 1){
			return isHome ?  
				[`${translate.instant('serviceStatus.oneDelegation')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] :
				[translate.instant('serviceStatus.oneDelegation')];
		}else {
			return isHome ? [`${translate.instant('serviceStatus.thereAre')} ${this.delegations} ${translate.instant('serviceStatus.delegations')} ${translate.instant('serviceStatus.inLocker')}`, ` ${this.locker.name}`] : 
			[`${translate.instant('serviceStatus.thereAre')} ${this.delegations} ${translate.instant('serviceStatus.delegations')}`];
			
		}
	}
}

@JsonObject("ServiceStatusArrayData")
export class ServiceStatusArrayData extends Resp { 
    @JsonProperty("data", ServiceStatusArrayConverter, true)
	data: Array<ServiceStatus> = [];
}