import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable, throwError } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BooleanResponse } from '../../models/boolean-response';
import { Code, CodeArrayData, CodeData } from '../../models/code';
import { CodeGenerateRequest } from '../../models/code-generate-request';
import { Delegation, DelegationData } from '../../models/delegation';
import { DelegationResponse, DelegationResponseData } from '../../models/delegation-response';
import { FreeBox, FreeBoxData } from '../../models/free-box';
import { Reservation, ReservationData } from '../../models/reservation';
import { ReservationResponse, ReservationResponseData } from '../../models/reservation-response';
import { ReservationType } from '../../models/reservation-type';
import { ServiceStatus, ServiceStatusArrayData } from '../../models/service-status';
import { HttpUtilsService } from '../http-utils.service';

@Injectable({
	providedIn: 'root'
})
export class LockerService {

	readonly endpoint: string = environment.baseUrl;

	constructor(private http: HttpClient,
		private httpUtils: HttpUtilsService) { }

	/**
	 * Get passed locker codes
	 * @param lockerUuid 
	 * @returns 
	 */
	getCodes(lockerUuid: string): Observable<Array<Code>> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/codes`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: CodeArrayData = jsonConvert.deserializeObject(res, CodeArrayData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Ensure errors are returned as Observables
						})
					);
			})
		);
	}

	/**
	 * Generate new code for passed locker with passed B2C code type
	 * @param lockerUuid 
	 * @param body 
	 * @param actionType 
	 * @returns 
	 */
	generateCode(lockerUuid: string, body: CodeGenerateRequest, actionType: ReservationType = ReservationType.EMPTY): Observable<Code> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.post(`${this.endpoint}/lockers/${lockerUuid}/code/${body.type}`, JSON.stringify(body.dto(actionType)), { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: CodeData = jsonConvert.deserializeObject(res, CodeData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return new Code(); // Consider handling this case more gracefully
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Refresh passed code pin 
	 * @param lockerUuid 
	 * @param vendor_token_uuid identify the pin on locker
	 * @param body 
	 * @returns 
	 */
	updateCode(lockerUuid: string, vendor_token_uuid: string, body: string): Observable<Code> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.put(`${this.endpoint}/lockers/${lockerUuid}/code/${vendor_token_uuid}`, body, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: CodeData = jsonConvert.deserializeObject(res, CodeData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return new Code(); // Consider handling this case more gracefully
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Delete passed code
	 * @param lockerUuid 
	 * @param vendor_token_uuid identify the pin on locker
	 * @returns 
	 */
	deleteCode(lockerUuid: string, vendor_token_uuid: string): Observable<boolean> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.delete(`${this.endpoint}/lockers/${lockerUuid}/code/${vendor_token_uuid}`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: BooleanResponse = jsonConvert.deserializeObject(res, BooleanResponse);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return false;
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Get user's pending operations on all lockers
	 * @param accountUuid 
	 * @returns 
	 */
	getPendings(accountUuid: string): Observable<Array<ServiceStatus>> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.get(`${this.endpoint}/users/${accountUuid}/lockers/operations`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: ServiceStatusArrayData = jsonConvert.deserializeObject(res, ServiceStatusArrayData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Get free boxes list for passed locker
	 * @param lockerUuid 
	 * @returns 
	 */
	getFreeBoxes(lockerUuid: string): Observable<Array<FreeBox>> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/freeBoxes`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: FreeBoxData = jsonConvert.deserializeObject(res, FreeBoxData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err);
						})
					);
			})
		);
	}

	/**
	 * Get user's reservations list
	 * @param accountUuid 
	 * @returns 
	 */
	getReservations(accountUuid: string): Observable<Array<Reservation>> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.get(`${this.endpoint}/users/${accountUuid}/lockers/reservations`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: ReservationData = jsonConvert.deserializeObject(res, ReservationData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Create new reservation
	 * @param accountUuid 
	 * @param lockerUuid 
	 * @param body 
	 * @returns 
	 */
	setReservation(accountUuid: string, lockerUuid: string, body: string): Observable<ReservationResponse> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.post(`${this.endpoint}/users/${accountUuid}/lockers/${lockerUuid}/reservations`, body, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: ReservationResponseData = jsonConvert.deserializeObject(res, ReservationResponseData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return new ReservationResponse();
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Delete a specific reservation
	 * @param accountUuid 
	 * @param lockerUuid 
	 * @param reservationUuid 
	 * @returns 
	 */
	deleteReservation(accountUuid: string, lockerUuid: string, reservationUuid: string): Observable<boolean> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.delete(`${this.endpoint}/users/${accountUuid}/lockers/${lockerUuid}/reservations/${reservationUuid}`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: BooleanResponse = jsonConvert.deserializeObject(res, BooleanResponse);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return false;
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Get user's delegations list
	 * @param accountUuid 
	 * @returns 
	 */
	getDelegations(accountUuid: string): Observable<Array<Delegation>> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.get(`${this.endpoint}/users/${accountUuid}/lockers/delegations`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: DelegationData = jsonConvert.deserializeObject(res, DelegationData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Use throwError for errors in RxJS observables
						})
					);
			})
		);
	}

	/**
	 * Create new delegation
	 * @param accountUuid 
	 * @param lockerUuid 
	 * @param body 
	 * @returns 
	 */
	setDelegation(accountUuid: string, lockerUuid: string, body: string): Observable<DelegationResponse> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.post(`${this.endpoint}/users/${accountUuid}/lockers/${lockerUuid}/delegations`, body, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: DelegationResponseData = jsonConvert.deserializeObject(res, DelegationResponseData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return new DelegationResponse();
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Correctly return an Observable error
						})
					);
			})
		);
	}

	/**
	 * Delete a specific delegation
	 * @param accountUuid 
	 * @param lockerUuid 
	 * @param delegationUuid 
	 * @returns 
	 */
	deleteDelegation(accountUuid: string, lockerUuid: string, delegationUuid: string): Observable<boolean> {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				return this.http.delete(`${this.endpoint}/users/${accountUuid}/lockers/${lockerUuid}/delegations/${delegationUuid}`, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: BooleanResponse = jsonConvert.deserializeObject(res, BooleanResponse);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return false;
							}
						}),
						catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							return throwError(err); // Use throwError for errors in RxJS observables
						})
					);
			})
		);
	}
}
