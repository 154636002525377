import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {
	showLoader: boolean = false;
	showSpinner: boolean = false;

	constructor(private loaderService: LoaderService, private spinnerService: SpinnerService) { }

	ngOnInit(): void {
		this.loaderService.showLoader$.subscribe(
			{
				next: l => { this.showLoader = l },
				error: err => { console.error(err) }
			});
		this.spinnerService.showSpinner$.subscribe(
			{
				next: s => { this.showSpinner = s },
				error: err => { console.error(err) }
			}
		);
	}

}
