import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { DomainConfig, DomainConfigConverter } from "./domain-config";
import { Resp } from "./resp";

@JsonConverter
export class DashboardConfigConverter implements JsonCustomConvert<DashboardConfig> {
    serialize() {}
    deserialize(data: any): DashboardConfig {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, DashboardConfig);
    }
}

@JsonConverter
export class DashboardConfigArrayConverter implements JsonCustomConvert<Array<DashboardConfig>> {
    serialize() {}
    deserialize(data: any): Array<DashboardConfig> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, DashboardConfig);
    }
}

@JsonObject("DashboardConfig")
export class DashboardConfig {
    @JsonProperty("domain_configuration", DomainConfigConverter)
    domain_configuration: DomainConfig = new DomainConfig;
    @JsonProperty("api_key", String, true)
    api_key: string = '';
    @JsonProperty("tenant", String, true)
    tenant: string = '';
    @JsonProperty("privacy_link_it", String, true)
    privacy_link_it: string = '';
    @JsonProperty("tc_link_it", String, true)
    tc_link_it: string = '';
    @JsonProperty("privacy_link_en", String, true)
    privacy_link_en: string = '';
    @JsonProperty("tc_link_en", String, true)
    tc_link_en: string = '';
}

@JsonObject("DashboardConfigData")
export class DashboardConfigData extends Resp {
    @JsonProperty("data", DashboardConfigConverter, true)
	data: DashboardConfig = new DashboardConfig();
}

@JsonObject("DashboardConfigArrayData")
export class DashboardConfigArrayData extends Resp {
    @JsonProperty("data", DashboardConfigArrayConverter, true)
    data: Array<DashboardConfig> = [];
}