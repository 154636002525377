import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ShowSideMenuService } from 'src/app/core/services/show-side-menu.service';

@Component({
	selector: 'dashboard-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.less']
})
export class PageNotFoundComponent implements OnInit {

	constructor(private loaderService: LoaderService, private showSideMenu: ShowSideMenuService) { }

	ngOnInit(): void {
		this.loaderService.update(false);
		this.showSideMenu.update(true);
	}

}
