<form nz-form [formGroup]="validateForm" class="mt-3">
    <nz-form-item [class]="justifyClass">
        <nz-form-control [nzSm]="14" [nzXs]="24">
            <app-section-cloudinary 
                [(src)]="avatarUrl!"
                [alignmentClass]="alignmentClass"></app-section-cloudinary>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [ngClass]="{'text-start': alignmentClass !== 'text-center'}" 
            [nzSpan]="alignmentClass !== 'text-center' ? 4 : 7" 
            nzRequired 
            nzFor="firstname">
            {{'account.accountForm.firstname' | translate}}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">
            <input nz-input formControlName="firstname" id="firstname" type="text"/>
        </nz-form-control>
    </nz-form-item>
    <!-- <nz-form-item>
        <nz-form-label nzFor="middlename">{{'account.accountForm.middleName' | translate}}</nz-form-label>
        <nz-form-control>
            <input nz-input formControlName="middlename" id="middlename" type="text"/>
        </nz-form-control>
    </nz-form-item> -->
    <nz-form-item>
        <nz-form-label [ngClass]="{'text-start': alignmentClass !== 'text-center'}" 
            [nzSpan]="alignmentClass !== 'text-center' ? 4 : 7" 
            nzRequired 
            nzFor="lastname">
            {{'account.accountForm.lastname' | translate}}
        </nz-form-label>
        <nz-form-control [nzSpan]="12">
            <input nz-input formControlName="lastname" id="lastname" type="text" />
        </nz-form-control>
    </nz-form-item>
    <!-- Not delete can be reintegrated -->
    <!-- <nz-form-item>
        <nz-form-label nzFor="gender">{{'account.accountForm.gender' | translate}}</nz-form-label>
        <nz-form-control class="text-start">
            <nz-select formControlName="gender">
                <nz-option *ngFor="let gender of genders" [nzValue]="gender" [nzLabel]="('account.genders.' + gender) | translate"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item> -->
    <!-- <nz-form-item>
        <nz-form-label nzFor="phonenumber">{{'account.accountForm.phoneNumber' | translate}}</nz-form-label>
        <nz-form-control>
            <input nz-input formControlName="phonenumber" id="phonenumber" type="text" />
        </nz-form-control>
    </nz-form-item> -->
</form>
