<nz-card nzTitle="{{'lockers.preferenceManagement' | translate | uppercase}}" class="h-100">
    <div class="h-100">
        <!-- Notification -->
        <div class="mb-4 d-flex">
            <div class="me-5">
                <nz-select [(ngModel)]="selectedNotification" (ngModelChange)="onChangeSelectNotification()" [nzPlaceHolder]="'lockers.selectNotificationSystem' | translate">
                    <nz-option *ngFor="let n of notifications" 
                        [nzValue]="n" 
                        [nzLabel]="('lockers.notificationSystem.' + n.toLowerCase()) | translate">
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <!-- Events to notify -->
        <!-- <div>
            <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="onCheckBoxChange($event)">
                <div nz-row>
                    <div nz-col nzXs="24" nzMd="12" nzLg="8" nzXl="6" *ngFor="let item of selectedCheckboxModel; let idx = index">
                        <label nz-checkbox [nzValue]="selectedCheckboxModel[idx].value" [ngModel]="selectedCheckboxModel[idx].checked">{{selectedCheckboxModel[idx].label | translate}}</label>
                    </div>
                </div>
            </nz-checkbox-wrapper>
        </div> -->
        <div>
            <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="onCheckBoxChange($event)">
                <nz-space [nzWrap]="true">
                    <div *ngFor="let item of selectedCheckboxModel; let idx = index">
                        <label nz-checkbox [nzValue]="selectedCheckboxModel[idx].value" [ngModel]="selectedCheckboxModel[idx].checked">{{selectedCheckboxModel[idx].label | translate}}</label>
                    </div>
                </nz-space>
            </nz-checkbox-wrapper>
        </div>

        <button class="mt-5 mb-5" nz-button nzType="primary" (click)="updateNotificationSettings()">{{'general.save' | translate}}</button>
        <div class="separator"></div>
        <div class="position-absolute bottom-0 pb-1">
            <p class="me-3"><span class="me-1" nz-icon nzType="info-circle" nzTheme="outline"></span>{{'lockers.warning' | translate}}</p>
        </div>
    </div>
   
</nz-card>