import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/core/models/account';
import { Menu } from 'src/app/core/models/menu';
import { MenuConfig } from 'src/app/core/config/menu-config';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ShowSideMenuService } from 'src/app/core/services/show-side-menu.service';
import { Appearance } from 'src/app/core/models/appearance';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { getAuth } from 'firebase/auth';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'dashboard-dashboard-base',
	templateUrl: './dashboard-base.component.html',
	styleUrls: ['./dashboard-base.component.less']
})
export class DashboardBaseComponent implements OnInit, OnDestroy {
	subscriptions: Array<Subscription> = [];
	showSideMenu: boolean = false;
	isCollapsed = false;
	today = new Date();
	menuConfig: Array<Menu> = MenuConfig;
	helpers = Helpers;
	apparence!: Appearance | null;
	account: Account = new Account();


	constructor(private router: Router, 
		private spinnerService: SpinnerService, 
		private loaderService: LoaderService, 
		private showSideMenuService: ShowSideMenuService,
		private configService: ConfigurationService,
		private identityService: IdentityService) {
		this.subscriptions.push(
			this.router.events.subscribe(
				{
					next: (event) => {				
						/* It's a switch statement that checks the type of the event, in order to show spinner on module change. */
						switch (true) {
							case event instanceof NavigationStart: {
								/* It's a module when navigationTrigger is equal to imperative, otherwhise is equal to popstate */
								if ((event as NavigationStart).navigationTrigger && (event as NavigationStart).navigationTrigger === 'imperative') {
									this.spinnerService.update(true);
								}
								break;
							}
							case event instanceof NavigationEnd:
							case event instanceof NavigationCancel:
							case event instanceof NavigationError: {
								setTimeout(() => {
									this.spinnerService.update(false);
								}, 500);
								break;
							}
							default: {
								break;
							}
						}
					},
					error: (err) => {
						console.error(err);
					}
				}
			)
		);
	}

	ngOnInit(): void {
		// Handle aside menu visibility
		this.subscriptions.push(this.showSideMenuService.showMenu$.subscribe(
			{
				next: (show) => {
					this.showSideMenu = show;
				},
				error: (err) => {
					console.error(err);
				}
			}
		));
		this.configService.setConfiguration();
		this.subscriptions.push(
			this.identityService.identity$.subscribe((identity) => {
				this.account = identity!;
			})
		);
	}
	

	/** Get logo big */
	public get logoBig() : string {
		if(this.apparence?.domain_logo){
			return this.apparence!.domain_logo;
		}else{
			return './assets/images/logo-big.png';
		}
	}

	/** Get logo small */
	public get logoSmall() : string {
		if(this.apparence?.domain_logo_small){
			return this.apparence!.domain_logo_small;
		}else{
			return './assets/images/logo-mini-md.png';
		}
	}

	/** Get account avatar */
	getAvatar() {
		if (this.account) {
			return this.account.avatar;
		} else {
			return '';
		}
	}

	/** Get username */
	getUserName() {
		if (this.account) {
			return this.account.first_name + ' ' + this.account.last_name;
		} else {
			return '';
		}
	}

	/** Get user initials */
	getUserInitals() {
		if (this.getUserName()) {
			const splitted: Array<string> = this.getUserName().split(' ');
			return splitted.map(e => e[0].toUpperCase()).join('');
		} else {
			return '';
		}
	}

	/** Get last login date */
	getLastLogin() {
		const firebaseUser = Helpers.getFirebaseUser();
		if (firebaseUser) {
			return firebaseUser.lastLoginAt || null;
		} else {
			return null;
		}
	}

	/**  Logs out the user and redirects to the login page */
	logout() {
		this.loaderService.update(true);
		Helpers.cleanStorage();
		localStorage.setItem('isLogout', "1");
		const auth = getAuth();
		auth.signOut().then(() => {
			localStorage.removeItem('isLogout');
			this.router.navigate([environment.firebase.params.loginPath]);
			this.identityService.update(null);
			this.loaderService.update(false);
		});
	}

	ngOnDestroy() {
		// Unsubscribe all subscriptions
		this.subscriptions.forEach(s => s.unsubscribe());
		this.configService.deleteStyle();
	}

}
