import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

@JsonConverter
export class ApparenceConverter implements JsonCustomConvert<Appearance> {
    serialize(){}
    deserialize(data: any): Appearance{
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Appearance);
    }
}

@JsonObject("Appearance")
export class Appearance {
    @JsonProperty("primary_color_accent", String, true)
    primary_color_accent: string = '#f07f23';
    @JsonProperty("secondaryColorAccent", String, true)
    secondary_color_accent: string = '#fff';
    @JsonProperty("tertiary_color_accent", String, true)
    tertiary_color_accent: string = '#0B4351';
    @JsonProperty("background_color", String, true)
    background_color: string = '#fff';
    @JsonProperty("success_color", String, true)
    success_color: string = '#264F36';
    @JsonProperty("error_color", String, true)
    error_color: string = '#DB0007';
    @JsonProperty("domain_name", String, true)
    domain_name: string = 'Smart Locker';
    @JsonProperty("domain_logo", String, true)
    domain_logo: string = './assets/images/logo-big.png';
    @JsonProperty("domain_logo_small", String, true)
    domain_logo_small: string = './assets/images/logo-mini-md.png';
}