import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonConverter
export class DelegationObjectArrayConverter implements JsonCustomConvert<Array<DelegationObject>> {
    serialize(){}
    deserialize(data: any): Array<DelegationObject>{
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, DelegationObject);
    }
}

@JsonConverter
export class DelegationArrayConverter implements JsonCustomConvert<Array<Delegation>> {
    serialize(){}
    deserialize(data: any): Array<Delegation>{
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Delegation);
    }
}

@JsonObject("DelegationObject")
export class DelegationObject {
    @JsonProperty("delegate_email", String, true)
	delegate_email: string = "";
    @JsonProperty("delegation_uuid", String, true)
	delegation_uuid: string = "";
}

@JsonObject("Delegation")
export class Delegation {
    @JsonProperty("locker_uuid", String, true)
	locker_uuid: string = "";
    @JsonProperty("delegations", DelegationObjectArrayConverter, true)
	delegations: Array<DelegationObject> = [];
}

@JsonObject("DelegationData")
export class DelegationData extends Resp {
    @JsonProperty("data", DelegationArrayConverter, true)
	data: Array<Delegation> = [];
}
