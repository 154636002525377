export const environment = {
	production: true,
	version: '1.1.0-DEV',
	environmentKey: 'CORPORATE',
	/**
	 * API configuration
	 */
	baseUrl: 'https://smartlocker-api.guglielmo.dev', // DEV
	
	/**
	 * Firebase configurations
	 */
	firebase: {		 
		// DEV
		configuration: {
			apiKey: "AIzaSyBYinmIA1tXwOjTW7cBlqFrc_p3Iq_0xPI",
			authDomain: "smartlocker-corporate-dev.firebaseapp.com",
			projectId: "smartlocker-corporate-dev",
			storageBucket: "smartlocker-corporate-dev.appspot.com",
			messagingSenderId: "383993704858",
			appId: "1:383993704858:web:54f3e8030f1eedacea4a23",
			measurementId: "G-6JBHS76284"
		},

		params: {
			enableFirestoreSync: true, // enable/disable autosync users with firestore
			toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
			toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
			loginPath: '/auth/login', // url for unauthenticated users - to use in combination with canActivate feature on a route
			authGuardFallbackURL: '/wizard', // url for unauthenticated users - to use in combination with canActivate feature on a route
			authGuardLoggedInURL: '/wizard', // url for authenticated users - to use in combination with canActivate feature on a route
			initializedUser: '/home', // url to redirect to if user is initialized
			passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
			passwordMinLength: 5, // Password length min/max in forms independently of each componenet min/max.
			// Same as password but for the name
			nameMaxLength: 50,
			nameMinLength: 2,
			// If set, sign-in/up form is not available until email has been verified.
			// Plus protected routes are still protected even though user is connected.
			guardProtectedRoutesUntilEmailIsVerified: true,
			enableEmailVerification: true, // default: true
		}
	},

	/**
	 * Cloudinary configuration
	*/
	cloudinary: {
		folder: 'nyec9pfx',
		cloudName: 'do6okmdil',
		endpoint: 'https://api.cloudinary.com/v1_1/do6okmdil/upload'
	},

	/** Stores links Corporate */
	apps: {
		appStore: "https://apps.apple.com/it/app/smart-locker-corporate/id1660532057",
		googlePlay: "https://play.google.com/store/apps/details?id=it.smartlocker.corporate.android",
	}
};
