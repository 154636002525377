import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { Code } from 'src/app/core/models/code';
import { deliveryDescription, externalDescription, returnDescription } from 'src/app/core/models/code-descriptions';
import { CodeGenerateRequest } from 'src/app/core/models/code-generate-request';
import { CodeType } from 'src/app/core/models/code-type';
import { Locker } from 'src/app/core/models/locker';
import { ReservationType } from 'src/app/core/models/reservation-type';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-pin-management',
	templateUrl: './pin-management.component.html',
	styleUrls: ['./pin-management.component.less']
})
export class PinManagementComponent implements OnInit, OnDestroy {
	@Input() locker: Locker = new Locker();
	@Output() codesChange = new EventEmitter<Array<Code>>();
	@Input() supportedPins: Array<CodeType> = [
		CodeType.MASTER,
		CodeType.B2C,
		CodeType.B2B,
		CodeType.PICKUP,
		CodeType.MOBILE,
		CodeType.EXTERNAL,
	];
	subscriptions: Array<Subscription> = [];

	loading: boolean = false;
	generateLoading: boolean = false;
	masterCode: Code = new Code(CodeType.MASTER);
	deliveryCode: Code = new Code(CodeType.B2C);
	returnCode: Code = new Code(CodeType.B2C);
	otherCodes: Array<Code> = [];
	reservationType = ReservationType;
	externalCode: Code = new Code(CodeType.EXTERNAL);
	codeType = CodeType;


	constructor(private lockerService: LockerService,
		private router: Router,
		private translate: TranslateService,
		private notification: NzNotificationService) { }

	ngOnInit(): void {
		this.getCodes();
	}

	/** Get account's saved codes */
	getCodes(): void {
		this.loading = true;
		this.subscriptions.push(
			this.lockerService.getCodes(this.locker.uuid).subscribe(
				{
					next: (resp) => {
						if (resp) {
							this.codesChange.emit(resp);
							// Get other codes
							this.otherCodes = resp.filter(code => code.type !== CodeType.MASTER && 
								code.type !== CodeType.MOBILE && code.type !== CodeType.EXTERNAL &&
								code.description !== deliveryDescription &&
								code.description !== returnDescription);
							// Get master code
							const masterCode = resp.find(c => c.type == CodeType.MASTER);
							if (masterCode) {
								this.masterCode = masterCode;
							} else {
								this.masterCode.locker_uuid = this.locker.uuid;
								this.masterCode.type = CodeType.MASTER;
							}
							// Get B2C codes (return and delivery)
							if (resp.some(c => c.type == CodeType.B2C)) {
								const b2bCodes = resp.filter(c => c.type == CodeType.B2C);
								if (b2bCodes.length > 0) {
									if (b2bCodes.some(code => code.description === deliveryDescription)) {
										this.deliveryCode = b2bCodes.filter(code => code.description === deliveryDescription)[0];
									} else {
										this.deliveryCode.locker_uuid = this.locker.uuid;
										this.deliveryCode.type = CodeType.B2C;
										this.deliveryCode.description = deliveryDescription;
									}
									if (b2bCodes.some(code => code.description === returnDescription)) {
										this.returnCode = b2bCodes.filter(code => code.description === returnDescription)[0];
									} else {
										this.returnCode.locker_uuid = this.locker.uuid;
										this.returnCode.type = CodeType.B2C;
										this.returnCode.description = returnDescription;
									}
								} else {
									this.deliveryCode.locker_uuid = this.locker.uuid;
									this.deliveryCode.type = CodeType.B2C;
									this.deliveryCode.description = deliveryDescription;
									this.returnCode.locker_uuid = this.locker.uuid;
									this.returnCode.type = CodeType.B2C;
									this.returnCode.description = returnDescription;
								}
							} else {
								this.deliveryCode.locker_uuid = this.locker.uuid;
								this.deliveryCode.type = CodeType.B2C;
								this.deliveryCode.description = deliveryDescription;
								this.returnCode.locker_uuid = this.locker.uuid;
								this.returnCode.type = CodeType.B2C;
								this.returnCode.description = returnDescription;
							}
							if (resp.some(c => c.type === CodeType.EXTERNAL)) {
								this.externalCode.code = resp.find(c => c.type === CodeType.EXTERNAL)?.code || "";
								this.externalCode.locker_uuid = this.locker.uuid;
								this.externalCode.type = CodeType.EXTERNAL;
								this.externalCode.description = externalDescription;
							}
						}
						this.loading = false;
					},
					error: (err) => {
						console.error(err);
						this.loading = false;
						if (err.status === 403) {
							Helpers.goToUnauthorized(this.router);
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGetCodes');
						}
					}
				}
			)
		);
	}

	/**
	 * Generate new code
	 * @param code code type
	 * @param actionType B2C code type
	*/
	generatePin(code: Code, actionType: ReservationType = ReservationType.EMPTY): void {
		this.generateLoading = true;
		const body: CodeGenerateRequest = new CodeGenerateRequest(code);
		this.subscriptions.push(
			this.lockerService.generateCode(this.locker.uuid, body, actionType).subscribe(
				{
					next: (resp) => {
						if (resp) {
							switch (resp.type) {
								case CodeType.MASTER:
									this.masterCode = resp;
									break;
								case CodeType.B2B:
								case CodeType.B2C:
									if (resp.description === deliveryDescription) {
										this.deliveryCode = resp;
									} else if (resp.description === returnDescription) {
										this.returnCode = resp;
									}
									break;
								case CodeType.EXTERNAL:
									this.externalCode = resp;
									break;
								default:
									break;
							}
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGenerateCode');
						}
						this.generateLoading = false;
					},
					error: (err) => {
						console.error(err);
						this.generateLoading = false;
						if (err.status === 403) {
							Helpers.goToUnauthorized(this.router);
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGenerateCode');
						}
					}
				}
			)
		);
	}

	isSupporterdCode(code: CodeType) {
		return this.supportedPins.some(p => p === code);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
