import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../helpers';

@Injectable({
	providedIn: 'root'
})
export class ApiKeyService {
	hasValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {
		this.update(Helpers.getApiKey()? true : false);
	}

	update(value: boolean) {
		this.hasValue$.next(value);
	}
}
