import { ReservationType } from "./reservation-type";

export class ReservationRequest {
    box_uuid: string = "";
    type: ReservationType = ReservationType.EMPTY;

	dto() {
		return {
			box_uuid: this.box_uuid,
			type: this.type
		}
	}
}
