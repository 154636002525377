import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class OperationConverter implements JsonCustomConvert<Operation | undefined> {
    serialize() {}
    deserialize(data: any): Operation | undefined {
        switch(data){
            case "SELFDEPOSIT":
                return Operation.SELFDEPOSIT;
            case "PICKUP":
                return Operation.PICKUP;
            case "EXCHANGE":
                return Operation.EXCHANGE;
            case "RESERVATION":
                return Operation.RESERVATION;
            case "DELEGATION":
                return Operation.DELEGATION;
            case "DELIVERY":
            default:
                return undefined;
        }
    }
}

/**
 * Operations
 * - SELFDEPOSIT
 * - DELIVERY
 * - PICKUP --> RETURN
 * - EXCHANGE
 * - RESERVATION
 * - DELEGATION
 */
export enum Operation {
    SELFDEPOSIT = 'SELFDEPOSIT',
    DELIVERY = 'DELIVERY',
    PICKUP = 'PICKUP',
    EXCHANGE = 'EXCHANGE',
    RESERVATION = 'RESERVATION',
    DELEGATION = 'DELEGATION',
}