import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { CustomValidators } from 'src/app/core/helpers/custom-validators';
import { Account } from 'src/app/core/models/account';
import { Gender } from 'src/app/core/models/gender';
import { IdentityService } from 'src/app/core/services/identity.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AccountService } from 'src/app/core/services/network/account.service';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.less']
})
export class UserProfileComponent implements OnInit, OnDestroy {

	@Input() justifyClass: string = 'justify-content-center';
	@Input() alignmentClass: string = 'text-center';

	validateForm!: UntypedFormGroup;
	avatarUrl: string = '';
	avatarLoading: boolean = false;
	deleteAvatarLoading: boolean = false;
	loading: boolean = false;
	account!: Account;
	genders: Array<Gender> = Object.values(Gender);
	subscriptions: Array<Subscription> = [];
	initStringAccount: string = "";

	constructor(private fb: UntypedFormBuilder,
		private accountService: AccountService,
		private router: Router,
		private translate: TranslateService,
		private notification: NzNotificationService,
		private loaderService: LoaderService,
		private identityService: IdentityService) { }

	ngOnInit(): void {
		this.loaderService.update(true);
		// Initialize form
		this.validateForm = this.fb.group({
			firstname: [null, [CustomValidators.required()]],
			lastname: [null, [CustomValidators.required()]],
			middlename: [null],
			gender: [null],
			phonenumber: [null]
		});
		// Get identity data
		this.subscriptions.push(this.identityService.identity$.subscribe((identity) => {
			if (identity) {
				this.account = identity;
				this.initStringAccount = JSON.stringify(this.account.dtoAll(true));
				this.validateForm.setValue(
					{
						firstname: this.account.first_name,
						lastname: this.account.last_name,
						middlename: this.account.middle_name,
						gender: this.account.gender,
						phonenumber: this.account.phone_number
					});
				this.avatarUrl = this.account.avatar;
				this.loaderService.update(false);
			}
		}));
	}

	/**
	 * Upadted account data
	 * @param showNotification show or not notification after account update
	 * @param callback 
	*/
	updateAccount(showNotification: boolean = true, callback: any = null): void {
		for (const i in this.validateForm.controls) {
			if (this.validateForm.controls.hasOwnProperty(i)) {
				this.validateForm.controls[i].markAsDirty();
				this.validateForm.controls[i].updateValueAndValidity();
			}
		}

		if (!this.validateForm.invalid) {
			this.loading = true;
			this.account.avatar = this.avatarUrl;
			this.account.first_name = this.validateForm.get("firstname")!.value.trim();
			this.account.last_name = this.validateForm.get("lastname")!.value.trim();
			this.account.middle_name = this.validateForm.get("middlename")?.value;
			this.account.phone_number = this.validateForm.get("phonenumber")?.value;
			this.account.gender = this.validateForm.get("gender")?.value;
			this.accountService.updateUser(this.account!).subscribe(
				{
					next: (resp) => {
						if (resp) {
							this.account = resp;
							this.identityService.update(this.account);
							const endStringAccount: string = JSON.stringify(resp.dtoAll(true));
							// Show notification if data is changed or if showNotification is true
							if (showNotification || this.initStringAccount !== endStringAccount) {
								Helpers.successNotification(this.notification, this.translate, 'account.successes.updatedAccount');
							}
							this.initStringAccount = endStringAccount;
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'account.errors.unableToUpdateAccount');
						}
						if (callback) { callback(); }
					},
					error: (err) => {
						console.error(err);
						this.loading = false;
						if (callback) { callback(); }
						if (err.status === 403) {
							Helpers.goToUnauthorized(this.router);
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'account.errors.unableToUpdateAccount');
						}
					}
				}
			);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
		this.identityService.reset();
	}
}
