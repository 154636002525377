import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account, AccountData } from '../../models/account';
import { HttpUtilsService } from '../http-utils.service';
import { DashboardConfig, DashboardConfigArrayData } from '../../models/dashboard-config';
import { Helpers } from '../../helpers';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	readonly endpoint: string = environment.baseUrl;

	constructor(private http: HttpClient, 
		private httpUtils: HttpUtilsService) { }

	/** Get user's data */
	me(): Observable<Account | undefined> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
					return this.http.get(`${this.endpoint}/me`, { headers: httpHeaders }).pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: AccountData = jsonConvert.deserializeObject(res, AccountData);
							if (result.success) {
								result.data.preferred_language = Helpers.getLanguage();
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return;
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						})));
				}), catchError(err => {
					throw err;
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/**
	 * It returns an Observable of an array of DashboardConfig objects
	 * @param {string} email - The email of the user who's dashboard configuration you want to retrieve.
	 * @returns An observable of an array of DashboardConfig objects.
	 */
	getConfiguration(email: string): Observable<Array<DashboardConfig>> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
					return this.http.get(`${this.endpoint}/configuration/${email}`, { headers: httpHeaders }).pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: DashboardConfigArrayData = jsonConvert.deserializeObject(res, DashboardConfigArrayData);
							if (result.success) {
								//console.log("result data:", result.data);
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						})));
				}), catchError(err => {
					throw err;
				})
			);
		} catch (error) {
			throw error;
		}
	}

	encodeEmail(email: string): string {
		return encodeURIComponent(email);
	}
}
