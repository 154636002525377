import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Address, AddressConverter } from "./address";
import { Resp } from "./resp";

@JsonConverter
export class LocationConverter implements JsonCustomConvert<Location> {
    serialize(){}
    deserialize(data: any): Location{
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Location);
    }
}

@JsonObject("Location")
export class Location {
    @JsonProperty("uuid", String, true)
    uuid: string = "";
    @JsonProperty("name", String, true)
    name: string = "";
    @JsonProperty("address", AddressConverter, true)
	address: Address = new Address();

    dto() {
        return {
            uuid: this.uuid,
            name: this.name,
            address: this.address.dto()
        }
    }
}

@JsonObject("LocationData")
export class LocationData extends Resp {
    @JsonProperty("data", LocationConverter, true)
    data: Location = new Location;
}