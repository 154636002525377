import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Appearance, ApparenceConverter } from "./appearance";

@JsonConverter
export class DomainConfigConverter implements JsonCustomConvert<DomainConfig> {
    serialize() {}
    deserialize(data: any): DomainConfig {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, DomainConfig);
    }
}

@JsonObject("DomainConfig")
export class DomainConfig {
    @JsonProperty("appearance", ApparenceConverter, true)
    appearance: Appearance = new Appearance;
}