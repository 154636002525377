import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class UserStatusConverter implements JsonCustomConvert<UserStatus> {
    serialize() {}
    deserialize(data: any): UserStatus {
        switch(data){
            case "REGISTERED":
                return UserStatus.REGISTERED;
            case "ACTIVE":
                return UserStatus.ACTIVE;
            case "SUSPENDED":
                return UserStatus.SUSPENDED;
            case "DELETED":
                return UserStatus.DELETED;
            default:
                return UserStatus.REGISTERED;
        }
    }
}

/**
 * User statuses:
 * - REGISTERED
 * - ACTIVE
 * - SUSPENDED
 * - DELETED
 */
export enum UserStatus {
    REGISTERED = "REGISTERED",
    ACTIVE = "ACTIVE",
    SUSPENDED = "SUSPENDED",
    DELETED = "DELETED"
}