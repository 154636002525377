export const locale = {
	lang: 'it',
	data: {
		login: {
			forgotPassword: 'Password dimenticata?',
			firstAccess: 'È il tuo primo accesso? Clicca qui',
			moreTenants: 'Il tuo account è associato a più ambienti.',
			selectOneTenant: 'Selezionane uno per effettuare l\'accesso:',
			errors: {
				email: 'Inserire un\'email valida',
				password: 'Inserire la password'
			}
		},
		firstAccess: {
			title: 'Crea la tua password',
			email: 'Inserisci la tua email',
			description: 'Inserisci il tuo indirizzo email. Ti invieremo una mail per la creazione della tua password.',
			button: 'Procedi',
			back: 'Torna alla pagina di login',
		},
		forgotPassword: {
			title: 'Recupera la tua password',
			email: 'Inserisci la tua email',
			description: 'Inserisci il tuo indirizzo email. Ti invieremo una mail per il reset della tua password.',
			button: 'Procedi',
			back: 'Torna alla pagina di login',
		},
		authentication: {
			sign_in: 'Login',
			register: 'Registrati',
			signing_in: 'Effettua il login',
			forgot_password: 'Hai dimenticato la password?',
			registration: 'Registrazione',
			reset_password: 'Reimposta la password',
			registration_completed: 'Registrazione completata',
			emailConfirmationText: 'Ti abbiamo inviato un\'email di verifica, controlla la tua inbox e clicca sul link per confermare il tuo indirizzo e-mail.',
			emailConfirmationTitle: 'Invia un\'email di conferma',
			resetSuccess: 'Ti abbiamo inviato un\'email conentente un link per ripristinare la password. Segui le istruzioni per ripristinare la password.',
			confirm_password: 'Conferma password',
			tos: 'Approvo le Condizioni e i Termini del Servizio',
			privacy: 'Ho letto e compreso la Privacy',
			error: 'Errore',
			google_sign_in: 'Accedi con Google',
			firstAccess: 'È il tuo primo accesso? Clicca qui',
			firstAccessTitle: 'Imposta la nuova password',
			new_password: 'Inserisci la tua email',
			emailSent: 'Email inviata con successo',
			newUserSuccess: 'Ti abbiamo inviato un\'email conentente un link per impostare la password. Segui le istruzioni per creare la password.',
			errors: {
				generic: 'Si è verificato un errore, si prega di riprovare più tardi',
				user_not_found: 'L\'utente non esiste o potrebbe essere stato cancellato.',
				user_disabled: 'L\'email inserita è disabilitata.',
				invalid_email: 'L\'indirizzo email inserito non è valido',
				wrong_password: 'La password non è valida per l\'email inserita, oppure l\'account corrispondente all\'email non ha la password impostata.',
				account_exists_with_different_credential: 'Esiste già un account associato a questa email',
				popup_blocked: 'Il popup è stato bloccato dal browser, si prega di abilitare i popup per questo sito web.',
				email_already_in_use: 'Esiste un altro account associato all\'indirizzo email inserito',
				weak_password: 'La password inserita non è abbastanza complicata',
				tos: 'Devi approvare le Condizioni e i Termini del Servizio',
				privacy: 'Devi approvare la Privacy',
				email_not_verified: 'Il tuo indirizzo email non è stato ancora verificato. Clicca sul link di verifica presente nell\'email che abbiamo inviato al tuo indirizzo.',
				unauthorized: 'L\'utente non è autorizzato all\'accesso',
				missingTenants: 'Errore di configurazione dei tenant',
				missingDashboardConfiguration: 'Errore di configurazione della dashboard',
				activateAccount: 'L\'account non è ancora attivo. Per favore verifica di aver ricevuto l\'email contenente il link per l\'attivazione.',
				accountSuspended: 'L\'account risulta sospeso. Per favore contatta il tuo amministratore per maggiori informazioni.',
				accountDeleted: 'L\'account risulta eliminato. Per favore contatta il tuo amministratore per maggiori informazioni.',
			}
		},
		general: {
			pageNotFound: 'La pagina non esiste',
			unauthorized: 'Non hai i permessi per visulizzare la pagina',
			spinnerMessage: 'Caricamento della pagina',
			loaderMessage: 'Caricamento dei dati',
			lastLogin: 'Ultimo accesso',
			nextButton: 'Avanti',
			preButton: 'Indietro',
			goToHome: 'Vai alla home',
			save: 'Salva',
			cancel: 'Annulla',
			image: 'Immagine',
			add: 'Carica',
			error: 'Errore',
			proceed: 'Procedi',
			email: 'Inserire un indirizzo email',
			required: 'Campo obbligatorio',
			selectedLocker: 'Locker selezionato',
			confirm: 'Conferma',
			downloadApp: 'Scarica l\'app',
		},
		cloudinary: {
			howToLoad: 'Scegli come caricare l\'immagine',
			addImage: 'Carica%20immagine',
			addNewImage: 'Carica un\'immagine',
			fromUrlText: 'Immagine da URL',
			fromFolderText: 'Immagine da file',
			remoteUrl: 'Inserire un URL remoto',
			chooseFile: 'Scegli un file',
			removeImage:'Rimuovi immagine',
            selectImage: 'Seleziona un\'immagine...',
            selctFromList: 'O scegli dalla lista',
            useThisImage: 'Usa questa immagine',
            deleteImage: 'Elimina questa immagine',
			limits: {
				msg1: 'L\'immagine deve essere larga ',
				msg2: ' e deve essere alta ',
			},
			errors: {
				emptyURL: 'Inserire un URL valido',
				upload: 'Si è verificato un errore, si prega di riprovare.',
				limits: `La dimensione dell'immagine è sbagliata: la larghezza deve essere %%%px, l'altezza deve essere: ###px`,
			}
		},
		serviceStatus: {
			deliveries: 'consegne',
			delivery: 'consegna',
			DELIVERY: 'Consegna',
			noDeliveries: 'Non sono presenti consegne',
			oneDelivery: 'È presente una consegna per te',
			deliveriesForYou: 'consegne per te',

			returns: 'resi',
			return: 'reso',
			RETURN: 'Reso',
			noReturns: 'Non sono presenti resi',
			oneReturn: 'È presente un reso in attesa di ritiro',
			awaitingCollection: 'in attesa di ritiro',

			selfDeposits: 'auto depositi',
			selfDeposit: 'auto deposito',

			exchanges: 'scambi',
			exchange: 'scambio',

			noReservations: 'Non sono presenti prenotazioni',
			oneReservation: 'È presente una prenotazione',
			reservations: 'prenotazioni',
			reservation: 'prenotazione',

			delegations: 'deleghe',
			delegation: 'delega',
			noDelegations: 'Non sono presenti deleghe',
			oneDelegation: 'È presente una delega',

			thereAre: 'Sono presenti',
			thereIs: 'C\'è',

			inLocker: 'nel locker',

			insertedByYou: 'inserito da te nel locker',
			insertedByYouPlural: 'inseriti da te nel locker',
			confirmDeleteReservation: 'Confermi di voler cancellare la prenotazione?',
			reservationDeleted: 'Prenotazione eliminata con successo',
			confirmDeleteDelegation: 'Confermi di voler cancellare la delega?',
			createDelegation: 'Crea una nuova delega',
			createDelegationSuccess: 'La delega è stata creata con successo',
			createReservation: 'Crea un nuova prenotazione',
			noFreeBoxes: 'Non sono disponibili box liberi',
			freeBoxes: 'Box liberi',
			freeBoxesTip: 'Seleziona un box libero',
			reservationType: 'Tipo di prenotazione',
			reservationTypeTip: 'Seleziona il tipo di prenotazione',

			createReservationSuccess: 'Prenotazione eseguita con successo',
			delegationDeleted: 'Delega eliminata con successo',
			errors: {
				unableToGetReservations: 'Non è stato possibile recupare la lista delle prenotazioni',
				unableToGetDelegations: 'Non è stato possibile recupare la lista delle deleghe',
				unableToDeleteReservation: 'Non è stato possibile eliminare la prenotazione',
				unableToDeleteDelegation: 'Non è stato possibile eliminare la delega',
				unableToCreateDelegation: 'Non è stato possibile ceare una nuova delega',
				unableToCreateDelegationUserNotExist: 'Non è stato possibile creare una nuova delega. L\'utente corrispondente all\'indirizzo email inserito non esiste.',
				unableToCreateDelegationUserAlreadyExist: 'Non è stato possibile creare una nuova delega. L\'utente è già presente fra le tue deleghe.',
				unableToGetFreeBoxes: 'Impossibile recuperare la lista dei box liberi del locker',
				unableToCreateReservation: 'Non è stato possibile ceare una nuova prenotazione',
			}
		},
		menu: {
			account: 'Account',
			home: 'Home',
			lockers: 'Locker'
		},
		home: {
			welcome: 'Smart Locker dashboard',
			message: 'Queste sono le attività che hai in sospeso sui tuoi locker',
			noPendingServices: 'Non hai nessuna attività in sospeso',
			errors: {
				unableToGetPendingService: 'Non è stato possibile recuperare la lista delle attività in attesa',
			}
		},
		account: {
			title: 'Account',
			personalDataUpdating: 'Modifica dei dati personali',
			changePassword: 'Modifica password',
			deleteAccount: 'Elimina account',
			viewTermsAndconditions: 'Visualizza \'Termini e condizioni\'',
			genders: {
				FEMALE: 'Femmina',
				MALE: 'Maschio',
				OTHER: 'Altro'
			},
			accountForm: {
				firstname: 'Nome',
				lastname: 'Cognome',
				middleName: 'Secondo nome',
				gender: 'Genere',
				phoneNumber: 'Telefono'
			},
			successes: {
				updatedAccount: 'Account modificato con successo',
				updateNotification: 'Le tue preferenze di notifica sono state aggiornate con successo'
			},
			errors: {
				unableToUpdateAccount: 'Non è stato possibile aggiornare l\'account',
				unableToGetAccount: 'Non è stato possibile recuperare i dati del tuo account',
				unableToUpdateNotification: 'Non è stato possibile aggiornare le tue preferenze di notifica'
			}	
		},
		lockers: {
			preferenceManagement: 'Gestione delle notifiche',
			selectNotificationSystem: 'Seleziona un sistema di notifica',
			warning: 'È possibile scegliere tra i sistemi di notifica messi a disposizione dall’amministratore del locker.',
			pendingServices: 'Attività in sospeso',
			noPendings: 'Non hai attività in sospeso su questo locker',
			notificationSystem: {
				name: 'Sistema di notifica',
				email: 'Email',
				push: 'Push'
			},
			operations: {
				name: 'Operazioni',
			},
			errors: {
				unableToGetLockers: 'Non è stato possibile recuperare la lista dei locker'
			}
		},
		wizard: {
			tcStep: {
				title: 'Termini e condizioni',
				subtitle: 'Leggi e accetta i nostri Termini e condizioni per procedere.',
				acceptTc: 'Dichiaro di aver letto ed accettato \'Termini e condizioni\'.',
				noLink: 'Non è stato possibile recuperare \'Termini e condizioni\''
			},
			accountStep: {
				name: 'Dati personali',
				welcome: 'Benvenuto su SmartLocker,',
				whatIsSmartLocker: 'il sistema che ti permette di ritirare e consegnare i tuoi pacchi quando decidi tu, senza dover aspettare il corriere nÈ rischiare di mancare una consegna.',
				description: 'Per prima cosa verifica i tuoi dati personali per iniziare a sfruttare le funzionalità messe a disposizione.',
			},
			lockerSteps: {
				name: 'Gestione Locker',
				associatedLockers: 'Locker associati',
				lockerConfiguration: 'Configurazione locker',
				autoGenerationPin: 'Questo locker prevede la generazione automatica del PIN di autenticazione personale e di quello di consegna, da condividere con le aziende di logistica per poter inserire i pacchi a te indirizzati.',
				manualGenerationPin: 'Questo locker prevede la possibilità di scegliere i tuoi PIN di accesso e consegna. Per farlo, inseriscili e clicca su “Avanti”.',
				manualGenerationPinAlternative: 'Altrimenti, se preferisci, puoi farli generare al sistema. Premi i due pulsanti di generazione e clicca su “Avanti”.',
				lockersDetected: 'Locker rilevati associati al tuo account:',
				description: 'Verranno ora effettuati i passaggi per permetterti di autenticarti sui dispositivi, ricevere le consegne e fare le restituzioni.',
				manageYourPins: 'Potrai gestirli dall’apposita sezione lockers.',
				configurationFor: 'Configurazione per:'
			},
			notificationsStep: {
				name: 'Notifiche',
				pushMessage: 'Se vuoi ricevere le notifiche istantanee relative alla consegna di un pacco o di un reso effettuati nel locker, puoi attivare le notifiche push dalla sezione "Account" dell\'applicazione.',
				emailMessage: 'Se preferisci, puoi anche attivare la ricezione dei messaggi di consegna, reso ed altre operazioni effettuate sul locker tramite email.',
				emailActiveMessage: 'Clicca su "Attiva notifiche email" per abilitarle.',
				emailFinalMessage: 'Potrai sempre modificare questa scelta nella sezione "Account" di questa dashboard oppure in quella dell\'applicazione.',
				enableButton: 'Attiva notifiche email',
				notEnableButton: 'Non attivare',
				successes: {
					enableUpdate: 'Notifiche email abilitate con successo',
					disableUpdate: 'Notifiche email disabilitate con successo'
				},
				errors: {
					unableToUpdateEmailNotifications: 'Non è stato possibile abilitare le notifiche via email'
				}
			},
			finalStep: {
				name: 'Fine',
				message: 'È tutto pronto per poter utilizzare il locker.',
				messageGoToHome: 'Clicca sul pulsante “Vai alla Home” per iniziare.',			
			}
		},
		pinManagement: {
			yourPins: 'I tuoi Pin associati:',
			yourOldPinsTitle: 'Altri Pin',
			yourOldPinsDescription: 'Questi sono i tuoi PIN già configurati ed associati a questo locker:',
			noPin: 'Nessun Pin',
			noDescription: 'Nessuna descrizione',
			master: 'Master',
			delivery: 'Consegna',
			deliveryB2C: 'Consegna B2C',
			deliveryB2B: 'Consegna B2B',
			pickup: 'Reso',
			external: 'Esterno',
			pinCreationLoading: 'Creazione dei pin...',
			generatePin: 'Genera pin',
			updateMaster: 'Rigenera il pin master', 
			updateDelivery: 'Rigenera il pin di consegna', 
			updateReturn: 'Rigenera il pin di reso', 
			updateExternal: 'Rigenera il pin esterno', 
			updateConfirmMessage: 'Attenzione: rigenerando questo PIN renderai immediatamente inutilizzabile quello precedente.',
			updateConfirmRequest: 'Vuoi procedere?',
			managePins: 'Gestione dei pin',
			masterPinDescription: 'Per l\'accesso diretto al locker tramite il monitor',
			deliveriesPinDescription: 'Da fornire ai servizi di logistica per effettuare le consegne',
			deliveriesB2CDescription: 'Questi pin sono per le consegne dei tuoi acquisti personali',
			deliveriesB2BDescription: 'Questi pin sono per le consegne degli ordini aziendali',
			returnPinDescription: 'Da fornire ai servizi di logistica per il ritiro dei resi',
			externalPinDescription: 'Per le consegne degli ordini o da fornire ai servizi logistica per il ritiro dei resi',
			errors:{
				unableToGetCodes: 'Non è stato possibile recuperare i pin per questo locker',
				unableToGenerateCode: 'Non è stato possibile generare il pin'
			}
		},
		public: {
			goToHome: 'Vai alla pagina di login',
			emailVerified: {
				title: 'La tua email è stata verificata con successo',
				subTitle: 'Premi il pulsante per tornare alla pagina di login',
				donwloadApp: 'In alternativa scarica l\'app Smart Locker',
			},
			emailNotFound: {
				title: 'Non è stato possibile verificare la tua email',
				subTitle: 'Il link per verificare la tua email è scaduto. Si prega di ripetere la procedura',
			},
			emailError: {
				title: 'Non è stato possibile verificare la tua email',
				subTitle: 'Si è verificato un errore durante la procedura di verifica dell\'indirizzo email. Si prega di ripetere la procedura',
			}
		}
	}
}