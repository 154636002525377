import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonConverter
export class ReservationResponseConverter implements JsonCustomConvert<ReservationResponse> {
	serialize() { }
	deserialize(data: any): ReservationResponse {
		const jsonConvert: JsonConvert = new JsonConvert();
		return jsonConvert.deserializeObject(data, ReservationResponse);
	}
}

@JsonObject("ReservationResponse")
export class ReservationResponse {
	@JsonProperty("reservation_uuid", String, true)
	reservation_uuid: string = "";
}

@JsonObject("ReservationResponseData")
export class ReservationResponseData extends Resp {
	@JsonProperty("data", ReservationResponseConverter, true)
	data: ReservationResponse = new ReservationResponse();
}