/**
 * Reservation types
 * - RETURN
 * - DELIVERY
 */
export enum ReservationType {
	RETURN = "RETURN",
	DELIVERY = "DELIVERY",
	EMPTY = ""
}
