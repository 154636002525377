import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonConverter
export class DelegationResponseConverter implements JsonCustomConvert<DelegationResponse> {
	serialize() { }
	deserialize(data: any): DelegationResponse {
		const jsonConvert: JsonConvert = new JsonConvert();
		return jsonConvert.deserializeObject(data, DelegationResponse);
	}
}

@JsonObject("DelegationResponse")
export class DelegationResponse {
	@JsonProperty("delegation_uuid", String, true)
	delegation_uuid: string = "";
}


@JsonObject("DelegationResponseData")
export class DelegationResponseData extends Resp {
	@JsonProperty("data", DelegationResponseConverter, true)
	data: DelegationResponse = new DelegationResponse();
}