import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as moment from 'moment';
import { Observable, from, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helpers } from '../helpers';
import { Resp } from '../models/resp';
import { IdentityService } from './identity.service';

@Injectable({
  providedIn: 'root'
})
export class HttpUtilsService {

	constructor(private router: Router, private identityService: IdentityService) { }

	/**
	 * Get standard header async 
	 * 
	 * If token is expired it will be refreshed, otherwise return current token
	 */
	getHTTPHeadersAsync(contentType?: string): Observable<any> {
		// Convert promise to observable
		// With promise + observable, caller wait for a response or error
		// Without promise + observable, caller get an error when user.stsTokenManager.expirationTime is expired
		return from(new Promise(resolve => {
			try {
				const header: any = {
					'Content-Type': contentType ? contentType : 'application/json',
					'X-Environment-Key': environment.environmentKey,
				};

				// Get user from local storage
				let user: any = Helpers.getFirebaseUser();
				if (user && user.uid && user.stsTokenManager) {
					header['X-User-Id'] = user.uid;
					if (Helpers.getApiKey()) {
						header['X-Api-Key'] = Helpers.getApiKey();
					}
					const expire = moment(user.stsTokenManager.expirationTime);
					const now = moment();
					// Check if token is expired
					if (expire.isAfter(now)) {
						try {
							header['X-User-Token'] = user.stsTokenManager.accessToken;
							return resolve(header);
						} catch (err) {
							return throwError(err);
						}
					} else {
						// If session is expired since 12 hours, refresh token and save it in localstorage
						const sessionExpire = Helpers.getSessionExpire();
						const auth = getAuth();
						if (sessionExpire.isAfter(now)) { // Session is valid
							onAuthStateChanged(auth, user => {
								if (user) {
									// Update user
									user.reload().then(() => {
										const currentUser = auth.currentUser;
										if (currentUser) {
											Helpers.setFirebaseUser(currentUser.toJSON())
											const currentUserJson: any = Helpers.getFirebaseUser();
											header['X-User-Token'] = currentUserJson.stsTokenManager.accessToken;
											return resolve(header);
										} else {
											return throwError({ message: 'User not found', value: 'User not found' });
										}
									}).catch((err => {
										console.error(err);
										return throwError({ message: 'Error during token reload', value: err });
									}));
								}
							});
						} else { // Session is expired more than 12 hours ago, logout
							Helpers.cleanStorage();
							localStorage.setItem('isLogout', "1");
							auth.signOut().then(() => {
								localStorage.removeItem('isLogout');
								this.router.navigate([environment.firebase.params.loginPath]);
								throw throwError({ message: 'Session expired', value: 'Session expired' });
							});
						}
					}
				} else {
					console.error('Unabled to get user');
					this.logout();
					return throwError('Unabled to get user');
				}
			} catch (err) {
				console.error(err);
				return throwError(err);
			}
		}));
	}

	/**
	 * Build stantard error message
	 * @param response 
	 */
	handleError(response: Resp): HttpErrorResponse {
		const err: any = {
			error: response.errorMessage,
			status: response.errorCode,
			statusText: response.errorMessage
		};
		const error: HttpErrorResponse = new HttpErrorResponse(err);

		if (response.errorCode === 401) { // Unauthorized
			this.logout();
		}
		throw error;
	}

	/** Logout from platform */
	logout() {
		Helpers.cleanStorage();
		localStorage.setItem('isLogout', "1");
		const auth = getAuth();
		auth.signOut().then(() => {
			localStorage.removeItem('isLogout');
			this.identityService.update(null);
			this.router.navigate([environment.firebase.params.loginPath]);
			throw throwError({ message: 'Session expired', value: 'Session expired' });
		});
	}
}
