<nz-layout class="app-layout h-100">
    <nz-layout class="h-100">
        <nz-header class="public-header">
            <div nz-row [nzGutter]="0">
                <div nz-col [nzSpan]="24" class="text-center text-lg-start">
					<img id="big-logo" alt="logo" src="./assets/images/logo-big.png">
				</div>
            </div>
        </nz-header>
        <nz-content>
            <div class="inner-content-transparent">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
		<app-version></app-version>
    </nz-layout>
</nz-layout>

