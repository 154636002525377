import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class NotificationEnumConverter implements JsonCustomConvert<NotificationEnum> {
    serialize() {}
    deserialize(data: any): NotificationEnum {
        switch(data){
            case "EMAIL":
            default:
                return NotificationEnum.EMAIL;    
        }        
    }
}

/**
 * Notifications
 * - EMAIL
 */
export enum NotificationEnum {
    EMAIL = 'EMAIL',
    EMPTY = ''
}
