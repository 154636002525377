import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class GenderConverter implements JsonCustomConvert<Gender> {
    serialize() {}
    deserialize(data: any): Gender {
        switch(data){
            case "MALE":
                return Gender.MALE;
            case "FEMALE":
                return Gender.FEMALE;
            case "OTHER":
                return Gender.OTHER;
            default:
                return Gender.OTHER;
        }
    }
}

/**
 * Genders
 * - MALE
 * - FEMALE
 * - OTHER
 */
export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER"
}