import { Code } from "./code";
import { deliveryDescription, externalDescription, masterDescription, mobileDescription, returnDescription } from "./code-descriptions";
import { CodeType } from "./code-type";
import { ReservationType } from "./reservation-type";

export class CodeGenerateRequest {
	description?: string = "";
	type: CodeType = CodeType.MASTER;

	/**
	 * Code generate request object, if pass code, automatically casts it
	 * @param code 
	 */
	constructor(code?: Code) {
		if (code) {
			this.description = code.description;
			this.type = code.type;
		}
	}

	/**
	 * Reservation dto
	 * @param actionType 
	 * @returns 
	 */
	dto(actionType: ReservationType = ReservationType.EMPTY) {
		if (!this.description) {
			switch (this.type) {
				case CodeType.MASTER:
					this.description = masterDescription;
					break;
				case CodeType.B2B:
				case CodeType.B2C:
					if (actionType === ReservationType.DELIVERY) {
						this.description = deliveryDescription;
					} else if (actionType === ReservationType.RETURN) {
						this.description = returnDescription;
					}
					break;
				case CodeType.MOBILE:
					this.description = mobileDescription;
					break;
				case CodeType.EXTERNAL:
					this.description = externalDescription;
					break;
				default:
					break;
			}
		}

		return {
			description: this.description,
			type: this.type
		}
	}
}
