import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { CustomValidators } from 'src/app/core/helpers/custom-validators';
import { Account } from 'src/app/core/models/account';
import { Box } from 'src/app/core/models/box';
import { Delegation, DelegationObject } from 'src/app/core/models/delegation';
import { DelegationRequest } from 'src/app/core/models/delegation-request';
import { FreeBox } from 'src/app/core/models/free-box';
import { Reservation, ReservationBox } from 'src/app/core/models/reservation';
import { ReservationRequest } from 'src/app/core/models/reservation-request';
import { ReservationType } from 'src/app/core/models/reservation-type';
import { ServiceStatus } from 'src/app/core/models/service-status';
import { IdentityService } from 'src/app/core/services/identity.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-service-status',
	templateUrl: './service-status.component.html',
	styleUrls: ['./service-status.component.less']
})
export class ServiceStatusComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() selectedUuid?: string = '';
	@Input() isHome: boolean = false;

	loading: boolean = false;
	pendings: ServiceStatus = new ServiceStatus;
	pendingsArray: Array<ServiceStatus> = [];
	account: Account = new Account;
	subscriptions: Array<Subscription> = []

	reservations: Array<Reservation> = [];
	reservationsBoxes: Array<ReservationBox> = [];
	delegations: Array<Delegation> = [];
	delegationsList: Array<DelegationObject> = [];
	helpers = Helpers;
	isVisibleNewDelegation: boolean = false;
	validateForm!: UntypedFormGroup;
	isConfirmLoading: boolean = false;
	isVisibleNewReservation: boolean = false;
	freeBoxes: Array<FreeBox> = [];
	reservation: ReservationRequest = new ReservationRequest();
	reservationType = ReservationType;
	lockerBoxes: Array<Box> = [];

	constructor(private lockerService: LockerService,
		private loaderService: LoaderService,
		public translate: TranslateService,
		private notification: NzNotificationService,
		private router: Router,
		private identityService: IdentityService,
		private fb: UntypedFormBuilder) { }

	ngOnInit(): void {
		this.validateForm = this.fb.group({
			email: [null, [CustomValidators.required(), CustomValidators.emailValidator()]],
		});
	}

	ngAfterViewInit(): void {
		this.subscriptions.push(this.identityService.identity$.subscribe(
			{
				next: (identity) => {
					if (identity) {
						this.account = identity!;
						this.getServiceStatus(this.selectedUuid);
						if (!this.isHome) {
							this.getReservationsData(this.selectedUuid);
							this.getDelegationsData(this.selectedUuid);
						}
					}
				},
				error: (err) => console.error(err)
			}
		))
	}

	/**
	 * Get service status
	 * @param lockerUuid 
	 */
	getServiceStatus(lockerUuid?: string) {
		this.loading = true;
		this.lockerService.getPendings(this.account.uuid).subscribe(
			{
				next: (resp) => {
					if (resp) {
						this.pendingsArray = resp;
						// Get locker specific data
						if (lockerUuid) {
							const foundPendings = resp.find(p => p.locker.uuid == lockerUuid);
							this.pendings = foundPendings ? foundPendings : new ServiceStatus();
						}
					}
					this.loading = false;
				},
				error: err => {
					console.error(err);
					this.loading = false;
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'home.errors.unableToGetPendingService');
					}
				}
			}
		);
	}

	getDeliveriesMessage(): string {
		const foundPending = this.pendingsArray.find(p => p.locker.uuid == this.selectedUuid);
		return foundPending ? foundPending.getDeliveriesMessage(this.translate, false)[0] : this.translate.instant('serviceStatus.noDeliveries');
	}

	getReturnsMessage(): string {
		const foundPending = this.pendingsArray.find(p => p.locker.uuid == this.selectedUuid);
		return foundPending ? foundPending.getReturnsMessage(this.translate, false)[0] : this.translate.instant('serviceStatus.noReturns');
	}

	getDelegationsMessage(): string {
		const foundPending = this.pendingsArray.find(p => p.locker.uuid == this.selectedUuid);
		return foundPending && foundPending.delegations > 0 ? foundPending.getDelegationsMessage(this.translate, false)[0] : this.translate.instant('serviceStatus.noDelegations');
	}

	/**
	 * Get reservetions list
	*/
	getReservationsData(lockerUuid: string = "") {
		lockerUuid = lockerUuid || this.selectedUuid || "";
		this.subscriptions.push(
			this.lockerService.getReservations(this.account.uuid).subscribe({
				next: response => {
					this.reservations = response;
					// Get current locker reservations
					if (this.reservations.some(r => r.locker_uuid === lockerUuid)) {
						this.reservationsBoxes = this.reservations.find(r => r.locker_uuid === lockerUuid)!.boxes || [];
					} else {
						this.reservationsBoxes = [];
					}
				}, error: err => {
					console.error(err);
					this.loading = false;
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToGetReservations');
					}
				}
			})
		);
	}

	getReservationMainMessage(pending: ServiceStatus, isHome: boolean): string {
		const mainMessage: string | undefined = pending.getReservationsMessage(this.translate, isHome).get("main");
		return mainMessage || '';
	}

	getReservationLockerNameForMessage(pending: ServiceStatus, isHome: boolean): string {
		const lockerName: string | undefined = pending.getReservationsMessage(this.translate, isHome).get("name");
		return lockerName || '';
	}

	/**
	 * Get delegations list
	*/
	getDelegationsData(lockerUuid: string = "") {
		lockerUuid = lockerUuid || this.selectedUuid || "";
		this.subscriptions.push(
			this.lockerService.getDelegations(this.account.uuid).subscribe({
				next: response => {
					this.delegations = response;
					// Get current locker delegations
					if (this.delegations.some(r => r.locker_uuid === lockerUuid)) {
						this.delegationsList = this.delegations.find(r => r.locker_uuid === lockerUuid)!.delegations || [];
					} else {
						this.delegationsList = [];
					}
				}, error: err => {
					console.error(err);
					this.loading = false;
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToGetDelegations');
					}
				}
			})
		);
	}

	/**
	 * Delete specified reservation
	 * @param reservation_uuid 
	*/
	deleteReservation(reservation_uuid: string) {
		this.loaderService.update(true);
		this.subscriptions.push(
			this.lockerService.deleteReservation(this.account.uuid, this.selectedUuid || "", reservation_uuid).subscribe({
				next: response => {
					if (response) {
						Helpers.successNotification(this.notification, this.translate, 'serviceStatus.reservationDeleted');
						this.getReservationsData();
						this.getServiceStatus(this.selectedUuid);
					}
					this.loaderService.update(false);
				}, error: err => {
					console.error(err);
					this.loaderService.update(false);
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToDeleteReservation');
					}
				}
			})
		);
	}

	/**
	 * Delete secified delegation
	 * @param delegation_uuid 
	*/
	deleteDelegation(delegation_uuid: string) {
		this.loaderService.update(true);
		this.subscriptions.push(
			this.lockerService.deleteDelegation(this.account.uuid, this.selectedUuid || "", delegation_uuid).subscribe({
				next: response => {
					if (response) {
						Helpers.successNotification(this.notification, this.translate, 'serviceStatus.delegationDeleted');
						this.getDelegationsData();
						this.getServiceStatus(this.selectedUuid);
					}
					this.loaderService.update(false);
				}, error: err => {
					console.error(err);
					this.loaderService.update(false);
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToDeleteDelegation');
					}
				}
			})
		);
	}

	/**
	 * Open new reservation modal
	*/
	openNewReservation(): void {
		this.reservation = new ReservationRequest();
		this.getLockerBoxes();
	}

	/**
	 * Open new delegation modal
	*/
	openNewDelegation(): void {
		this.validateForm.reset();
		this.isVisibleNewDelegation = true;
	}

	/**
	 * Create new reservation
	*/
	newReservation(): void {
		this.isConfirmLoading = true;
		this.loaderService.update(true);
		const body: string = JSON.stringify(this.reservation.dto());
		this.subscriptions.push(
			this.lockerService.setReservation(this.account.uuid, this.selectedUuid || "", body).subscribe({
				next: response => {
					if (response && response.reservation_uuid) {
						// Reload page data
						this.getServiceStatus(this.selectedUuid);
						this.getReservationsData();
						Helpers.successNotification(this.notification, this.translate, 'serviceStatus.createReservationSuccess');
					}
					this.isConfirmLoading = false;
					this.isVisibleNewReservation = false;
					this.loaderService.update(false);
				}, error: err => {
					console.error(err);
					this.loaderService.update(false);
					this.isConfirmLoading = false;
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToCreateReservation');
					}
				}
			})
		);
	}

	/**
	 * Create new delegation
	*/
	newDelegation(): void {
		this.isConfirmLoading = true;
		// Validate data
		Object.keys(this.validateForm.controls).forEach(key => {
			const control = this.validateForm.controls[key];
			control.markAsDirty();
			control.updateValueAndValidity();
		});

		if (!this.validateForm.invalid) {
			this.loaderService.update(true);
			const delegation: DelegationRequest = new DelegationRequest();
			delegation.delegate_email = this.validateForm.get('email')!.value.trim();
			const body: string = JSON.stringify(delegation.dto());
			this.subscriptions.push(
				this.lockerService.setDelegation(this.account.uuid, this.selectedUuid || '', body).subscribe({
					next: response => {
						if (response && response.delegation_uuid) {
							// Reload page data
							this.getDelegationsData();
							this.getServiceStatus(this.selectedUuid);
							Helpers.successNotification(this.notification, this.translate, 'serviceStatus.createDelegationSuccess');
						}
						this.isConfirmLoading = false;
						this.isVisibleNewDelegation = false;
						this.loaderService.update(false);
					}, error: err => {
						console.error(err);
						this.loaderService.update(false);
						this.isConfirmLoading = false;
						if (err.status === 403) {
							Helpers.goToUnauthorized(this.router);
						} else if (err.status === 404) {
							Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToCreateDelegationUserNotExist');
						} else if (err.status === 409) {
							Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToCreateDelegationUserAlreadyExist');
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToCreateDelegation');
						}
					}
				})
			);
		} else {
			this.isConfirmLoading = false;
			Helpers.errorNotification(this.notification, this.translate, 'login.errors.email');
		}
	}

	/**
	 * Get current locker free boxes list
	*/
	getLockerBoxes() {
		this.freeBoxes = [];
		this.loaderService.update(true);
		this.subscriptions.push(
			this.lockerService.getFreeBoxes(this.account.uuid).subscribe({
				next: response => {
					this.freeBoxes = response;
					// Sort boxes by name
					this.freeBoxes.forEach(fb => {
						fb.boxes.sort((a, b) => a.box_name.localeCompare(b.box_name));
					});
					if (this.freeBoxes.some(fb => fb.locker_uuid === this.selectedUuid)) {
						this.lockerBoxes = this.freeBoxes.find(fb => fb.locker_uuid === this.selectedUuid)?.boxes || [];
					} else {
						this.lockerBoxes = [];
					}
					this.loaderService.update(false);
					this.isVisibleNewReservation = true;
				}, error: err => {
					console.error(err);
					this.loaderService.update(false);
					this.isConfirmLoading = false;
					if (err.status === 403) {
						Helpers.goToUnauthorized(this.router);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'serviceStatus.errors.unableToGetFreeBoxes');
					}
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
