import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { CodeType, CodeTypeConverter } from "./code-type";
import { Resp } from "./resp";

@JsonConverter
export class CodeArrayConverter implements JsonCustomConvert<Array<Code>> {
    serialize() {}
    deserialize(data: any): Array<Code> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Code);
    }
}

@JsonConverter
export class CodeConverter implements JsonCustomConvert<Code> {
    serialize() {}
    deserialize(data: any): Code {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Code);
    }
}

@JsonObject("Code")
export class Code {
    @JsonProperty("locker_uuid", String, true)
    locker_uuid: string = '';
    @JsonProperty("code", String, true)
    code: string = '';
    @JsonProperty("type", CodeTypeConverter, true)
    type: CodeType = CodeType.MASTER;
    @JsonProperty("description", String, true)
    description?: string = '';
    @JsonProperty("vendor_token_uuid", String, true)
    vendor_token_uuid: string = '';

    constructor(type: CodeType = CodeType.MASTER)  {
        this.type = type;
    }

    dto() {
        return {
            locker_uuid: this.locker_uuid,
            code: this.code,
            type: this.type,
            description: this.description,
            vendor_token_uuid: this.vendor_token_uuid
        }
    }
}

@JsonObject("CodeData")
export class CodeData extends Resp {
    @JsonProperty("data", CodeConverter, true)
    data: Code = new Code;
}

@JsonObject("CodeArrayData")
export class CodeArrayData extends Resp {
    @JsonProperty("data", CodeArrayConverter, true)
    data: Array<Code> = [];
}