<div class="row justify-content-between mt-4 mb-4">
    <!-- DELIVERIES -->
    <div [ngClass]="{'col-12 col-lg-6': isHome, 'col-12': !isHome}">
        <div class="service-status-container">
            <div class="w-100 service-status-title" style="background-color: #FF5757;">
                {{'serviceStatus.deliveries' | translate | uppercase}}
            </div>
            <!-- <p *ngIf="!isHome" class="pt-3 ps-2 pe-2">{{this.pendings? this.pendings.getDeliveriesMessage(this.translate, false)[0] : '---'}}</p> -->
            <p *ngIf="!isHome" class="pt-3 ps-2 pe-2">{{this.getDeliveriesMessage()}}</p>
            <div class="pt-3 ps-3 pe-2" *ngIf="isHome">
                <ng-container *ngIf="pendingsArray.length > 0;else noData">
                    <nz-timeline>
                        <nz-timeline-item *ngFor="let pending of pendingsArray" class="pt-3 ps-3">
                            {{pending.getDeliveriesMessage(this.translate, true)[0]}}<span class="fw-bold">{{pending.getDeliveriesMessage(this.translate, true)[1]}}</span>
                        </nz-timeline-item>
                    </nz-timeline>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- RETURNS -->
    <div class="mt-2" [ngClass]="{'col-12 col-lg-6 mt-lg-0': isHome, 'col-12': !isHome}">
        <div class="service-status-container">
            <div class="w-100 service-status-title" style="background-color: #EAF7FF;">
                {{'serviceStatus.returns' | translate | uppercase}}
            </div>
            <!-- <p *ngIf="!isHome" class="pt-3 ps-2 pe-2">{{this.pendings? this.pendings.getReturnsMessage(this.translate, false)[0] : '---'}}</p> -->
            <p *ngIf="!isHome" class="pt-3 ps-2 pe-2">{{this.getReturnsMessage()}}</p>
            <div class="pt-3 ps-3 pe-2" *ngIf="isHome">
                <ng-container *ngIf="pendingsArray.length > 0;else noData">
                    <nz-timeline>
                        <nz-timeline-item *ngFor="let pending of pendingsArray" class="pt-3 ps-3">
                            {{pending.getReturnsMessage(this.translate, true)[0]}}<span class="fw-bold">{{pending.getReturnsMessage(this.translate, true)[1]}}</span>
                        </nz-timeline-item>
                    </nz-timeline>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- RESERVATIONS -->
    <!-- <div class="mt-2" [ngClass]="{'col-12 col-lg-6': isHome, 'col-12': !isHome}">
        <div class="service-status-container">
            <div class="w-100 service-status-title" style="background-color: #FBB600;">
                {{'serviceStatus.reservations' | translate | uppercase}}
            </div>
            <ng-container *ngIf="!isHome" >
                <p class="pt-3 ps-2 pe-2">{{this.pendings? getReservationMainMessage(this.pendings, false) : '---'}}</p>
                <div class="pt-3 ps-3 pe-2">
                    <nz-timeline class="timeline-margin" *ngIf="reservationsBoxes.length > 0">
                        <nz-timeline-item *ngFor="let box of reservationsBoxes" class="pt-3 ps-3">
                            <table class="w-100 pt-0 pb-0">
                                <tr class="pt-0">
                                    <td class="pt-0">
                                        <ng-container *ngIf="box.valid_until">
                                            {{box.valid_until | date: 'medium': undefined: helpers.getLanguage()}} - 
                                        </ng-container>
                                        Box {{box.box_name}} {{box.box_size}}</td>
                                    <td class="text-end pt-0">
                                        <a nz-button nzType="text" nz-popconfirm
                                            nzPopconfirmTitle="{{'serviceStatus.confirmDeleteReservation' | translate}}"
                                            nzOkText="{{'general.proceed' | translate}}"
                                            nzCancelText="{{'general.cancel' | translate}}"
                                            (nzOnConfirm)="deleteReservation(box.reservation_uuid)">
                                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </nz-timeline-item>
                    </nz-timeline><br />
                    <button nz-button nzType="dashed" nzBlock class="mb-2" (click)="openNewReservation()"><span nz-icon nzType="plus" nzTheme="outline"></span></button>
                </div>
            </ng-container>
            <ng-container *ngIf="isHome" >
                <div class="pt-3 ps-3 pe-2">
                    <ng-container *ngIf="pendingsArray.length > 0;else noData">
                        <nz-timeline>
                            <nz-timeline-item *ngFor="let pending of pendingsArray" class="pt-3 ps-3">
                                {{getReservationMainMessage(pending, true)}}
                                    <span class="fw-bold">{{getReservationLockerNameForMessage(pending, true)}}</span>
                            </nz-timeline-item>
                        </nz-timeline>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div> -->
    <!-- DELEGATIONS -->
    <div class="mt-2" [ngClass]="{'col-12 col-lg-6': isHome, 'col-12': !isHome}">
        <div class="service-status-container">
            <div class="w-100 service-status-title" style="background-color: #8CCEB1;">
                {{'serviceStatus.delegations' | translate | uppercase}}
            </div>
            <ng-container *ngIf="!isHome">
                <!-- <p class="pt-3 ps-2 pe-2">{{this.pendings? this.pendings.getDelegationsMessage(this.translate, false)[0] : '---'}}</p> -->
                <p class="pt-3 ps-2 pe-2">{{this.getDelegationsMessage()}}</p>
                <div class="pt-3 ps-3 pe-2">
                    <nz-timeline class="timeline-margin">
                        <nz-timeline-item *ngFor="let item of delegationsList" class="pt-3 ps-3">
                            <table class="w-100 pt-0 pb-0">
                                <tr class="pt-0">
                                    <td class="pt-0">{{item.delegate_email}}</td>
                                    <td class="text-end pt-0">
                                        <a nz-button nzType="text"
                                            nz-popconfirm
                                            nzPopconfirmTitle="{{'serviceStatus.confirmDeleteDelegation' | translate}}"
                                            nzOkText="{{'general.proceed' | translate}}"
                                            nzCancelText="{{'general.cancel' | translate}}"
                                            (nzOnConfirm)="deleteDelegation(item.delegation_uuid)">
                                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </nz-timeline-item>
                    </nz-timeline><br />
                    <button nz-button nzType="dashed" nzBlock class="mb-2" (click)="openNewDelegation()"><span nz-icon nzType="plus" nzTheme="outline"></span></button>
                </div>
            </ng-container>
            <ng-container *ngIf="isHome">
                <div class="pt-3 ps-3 pe-2">
                    <ng-container *ngIf="pendingsArray.length > 0;else noData">
                        <nz-timeline>
                            <nz-timeline-item *ngFor="let pending of pendingsArray" class="pt-3 ps-3">{{pending.getDelegationsMessage(this.translate, true)[0]}}
                                <span class="fw-bold">{{pending.getDelegationsMessage(this.translate, true)[1]}}</span>
                            </nz-timeline-item>
                        </nz-timeline>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- SELF DEPOSITS -->
<!--     <div class="mt-2" [ngClass]="{'col-12 col-lg-6': isHome, 'col-12': !isHome}">
        <div class="service-status-container">
            <div class="w-100 service-status-title" style="background-color: #ce7870;">
                {{'serviceStatus.selfDeposits' | translate | uppercase}}
            </div>
            <p *ngIf="!isHome" class="pt-3 ps-2 pe-2">{{this.pendings.getSelfDeposit(this.translate)}}</p>
            <div class="pt-3 ps-3 pe-2" *ngIf="isHome">
                <ng-container *ngIf="pendingsArray.length > 0;else noData">
                    <nz-timeline>
                        <nz-timeline-item *ngFor="let pending of pendingsArray" class="pt-3 ps-3">{{pending.getSelfDeposit(this.translate)}}</nz-timeline-item>
                    </nz-timeline>
                </ng-container>
            </div>
        </div>
    </div> -->
    <!-- EXCHANGES -->
<!--     <div class="mt-2" [ngClass]="{'col-12 col-lg-6': isHome, 'col-12': !isHome}">
        <div class="service-status-container">
            <div class="w-100 service-status-title" style="background-color: #ecbcb0;">
                {{'serviceStatus.exchanges' | translate | uppercase}}
            </div>
            <p *ngIf="!isHome" class="pt-3 ps-2 pe-2">{{this.pendings.getExchanges(this.translate)}}</p>
            <div class="pt-3 ps-3 pe-2" *ngIf="isHome">
                <ng-container *ngIf="pendingsArray.length > 0;else noData">
                    <nz-timeline>
                        <nz-timeline-item *ngFor="let pending of pendingsArray" class="pt-3 ps-3">{{pending.getExchanges(this.translate)}}</nz-timeline-item>
                    </nz-timeline>
                </ng-container>
            </div>
        </div>
    </div> -->
</div>

<!-- Reservation modal -->
<nz-modal [nzVisible]="isVisibleNewReservation" nzTitle="{{'serviceStatus.createReservation' | translate}}" (nzOnCancel)="isVisibleNewReservation = !isVisibleNewReservation">
	<ng-container *nzModalContent>
		<div *ngIf="lockerBoxes.length > 0; else noFreeBoxes" class="row">
            <div class="col">
                <form nz-form>
                    <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="box">{{'serviceStatus.freeBoxes' | translate}}</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24">
                            <nz-select [(ngModel)]="reservation.box_uuid" id="box" [ngModelOptions]="{standalone: true}" nzPlaceHolder="{{'serviceStatus.freeBoxesTip' | translate}}">
                                <nz-option *ngFor="let item of lockerBoxes" [nzValue]="item.box_uuid" nzLabel="Box {{item.box_name}} - {{item.box_size}}"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="reservationType">{{'serviceStatus.reservationType' | translate}}</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24">
                            <nz-select [(ngModel)]="reservation.type" id="reservationType" [ngModelOptions]="{standalone: true}" 
                                nzPlaceHolder="{{'serviceStatus.reservationTypeTip' | translate}}">
                                <nz-option [nzValue]="reservationType.DELIVERY" nzLabel="{{ ('serviceStatus.' + reservationType.DELIVERY) | translate }}"></nz-option>
                                <nz-option [nzValue]="reservationType.RETURN" nzLabel="{{ ('serviceStatus.' + reservationType.RETURN) | translate }}"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSm]="23" [nzXs]="23" nzRequired class="text-start" [nzNoColon]="true">{{'general.required' | translate}}</nz-form-label>
                        <nz-form-control [nzSm]="1" [nzXs]="1">
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>
        </div>
        <ng-template #noFreeBoxes>
            <nz-empty nzNotFoundImage="simple" nzNotFoundContent="{{'serviceStatus.noFreeBoxes' | translate}}"></nz-empty>
        </ng-template>
	</ng-container>
	<div *nzModalFooter>
        <button nz-button nzType="default" (click)="isVisibleNewReservation = !isVisibleNewReservation">{{'general.cancel' | translate}}</button>
        <button nz-button nzType="primary" (click)="newReservation()" [nzLoading]="isConfirmLoading" [disabled]="!reservation.box_uuid || reservation.type === reservationType.EMPTY">{{'general.proceed' | translate}}</button>
    </div>
</nz-modal>

<!-- Delegation modal -->
<nz-modal [nzVisible]="isVisibleNewDelegation" nzTitle="{{'serviceStatus.createDelegation' | translate}}" (nzOnCancel)="isVisibleNewDelegation = !isVisibleNewDelegation">
	<ng-container *nzModalContent>
		<form nz-form [formGroup]="validateForm" class="login-form" autocomplete="off">
			<nz-form-item class="text-start">
				<nz-form-control nzHasFeedback>
					<nz-input-group nzPrefixIcon="user">
						<input type="text" nz-input formControlName="email" placeholder="{{'general.email' | translate}}" />
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
		</form>
	</ng-container>
	<div *nzModalFooter>
        <button nz-button nzType="default" (click)="isVisibleNewDelegation = !isVisibleNewDelegation">{{'general.cancel' | translate}}</button>
        <button nz-button nzType="primary" (click)="newDelegation()" [nzLoading]="isConfirmLoading" [disabled]="!validateForm.valid">{{'general.proceed' | translate}}</button>
    </div>
</nz-modal>

<ng-template #noData>
    <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>