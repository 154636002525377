import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Helpers } from "src/app/core/helpers";
import { ImageShape } from "src/app/core/models/image-shape";
import { environment } from "src/environments/environment";

/** Cloudinary API component */
@Component({
  selector: 'app-section-cloudinary',
  templateUrl: './cloudinary.component.html',
  styleUrls: ['./cloudinary.component.less']
})
export class CloudinaryComponent implements AfterViewInit, OnInit {
  /** This is a property that is used to set the value of the src attribute of the image. */
  @Input() src: string = "";
  @Output() srcChange = new EventEmitter<string>();
  @Input() canDelete: boolean = true;
  @Input() canEdit: boolean = true;
  /* These are the properties that are used to set the values of the properties that are used in the Cloudinary API. */
  /** The Cloudinary API folder where upload the image. */
  folder: string = "";
  /** The Cloudinary API Cloud Name (actually not in use). */
  cloudName: string = "";
  /** This Cloudinary API endpoint. */
  cloudEndpoint: string = "";
  /** This is a property that is used to set the value of the alligment of the image. */
  @Input() alignmentClass: string = "text-center";
  /** This is a property that is used to verify image bounds */
  @Input() limits?: { width: number, height: number } = undefined;
  /* This is a property that is used to emit an event when the image is uploaded. */
  @Output() imageUploaded = new EventEmitter<boolean>();

  /** It is used to create an alias for the ImageShape Enum. */
  _ImageShape = ImageShape;
  /** This is a property that is used to set the value of the class attribute of the image. */
  shape: ImageShape = ImageShape.Square;
  /** This is a property that is used to set the visibility of the loader. */
  isLoading: boolean = false;
  /** This is a placeholder image that is used when the user does not upload an image. */
  baseImage: string = "https://via.placeholder.com/200x130/cccccc/969696?text=";
  /** This is the URL of the image to upload. */
  fromUrl: string = "";
  /** The selected type of file to upload (from remote URL or from local file). */
  loadType: string = "";
  /* This is a property that is used to set the visibility of the modal. */
  isVisible: boolean = false;
  /* This is the list of file to upload. */
  fileList: NzUploadFile[] = [];
  /* This is a property that is used to set the value of the error message. */
  error: string = "";
  

  /* This is a function that is used to get the file to upload to Cloudinary from the nz-upload component. */
  beforeUpload = (file: NzUploadFile): boolean => {
      this.fileList = this.fileList.concat(file);
      this.uploadFile(file);
      return false;
  };

  constructor(private translate: TranslateService, private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cloudEndpoint = environment.cloudinary.endpoint;
    this.cloudName = environment.cloudinary.cloudName;
    this.folder = environment.cloudinary.folder;
  }

  ngAfterViewInit() {
      /* This is a workaround to make sure that the translate service is initialized before the
      baseImage is updated. */
      setTimeout(() => {
          this.baseImage += this.translate.instant('cloudinary.addImage');
          this.chRef.detectChanges();
      }, 1);
  }

  /**
   * Open upload modal
   */
  upload() {
      this.loadType = "";
      this.error = "";
      this.fromUrl = "";
      this.fileList = [];
      this.isVisible = true;
  }

  /** Clear the current image */
  deleteImage() {
      this.shape = ImageShape.Square;
      this.src = '';
      this.srcChange.emit(this.src);
  }

  /**
   * This function is used to initialize the cloudinary file upload type
   * @param {string} type - string
   */
  initCloudinaryFileUpload(type: string) {
      this.loadType = type;
      this.error = "";
      if (this.loadType === 'fromFolderText') {
          this.fileList = [];
          this.chRef.detectChanges();
      }
  }

  /**
   * If the URL is valid, upload the file to Cloudinary
   */
  saveUrl() {
      if (Helpers.validateUrl(this.fromUrl)) {
          this.uploadFile(this.fromUrl);
      } else {
          this.error = this.translate.instant('cloudinary.errors.emptyURL');
      }
  }

  /**
   * It uploads the file to Cloudinary and sets the src property to the URL of the uploaded image.
   * @param {string | any} file - The file to upload.
   */
  uploadFile(file: string | any, isUrl: boolean = false) {
      let canUpload: boolean = true;

      const makeUpload = () => {
          if (!canUpload) {
              this.isLoading = false;
              console.error(`Image size is wrong, width: ${this.limits!.width}px, height: ${this.limits!.height}px`);
              this.error = this.translate.instant('cloudinary.errors.limits').replace('%%%', this.limits!.width.toString()).replace('###', this.limits!.height.toString());
          } else {
              const url: string = this.cloudEndpoint;
              const fd: FormData = new FormData();
              fd.append('upload_preset', this.folder);
              fd.append('file', file);
              this.isLoading = true;
              fetch(url, {
                  method: 'POST',
                  body: fd
              })
                  .then(this.status)
                  .then(this.progress)
                  .then((response: any) => {
                      this.setShape(response.width, response.height);
                      this.isLoading = false;
                      this.src = response.secure_url;
                      this.srcChange.emit(this.src);
                      this.imageUploaded.emit(true);
      
                      this.isVisible = false;
                  }).catch((error) => {
                      this.isLoading = false;
                      console.error('Request failed', error);
                      this.error = this.translate.instant('cloudinary.errors.upload');
                  });
          }
      }

      if (this.limits && !isUrl) {
          const img = new Image();
          const _URL = window.URL || window.webkitURL;
          const objectUrl = _URL.createObjectURL(file);
          const self = this;
          img.onload = function () {
              if (self.limits!.width !== (<any>this).width || self.limits!.height !== (<any>this).height) {
                  canUpload = false;
              }
              _URL.revokeObjectURL(objectUrl);
              makeUpload();
          };
          img.src = objectUrl;
          return;
      } else {
          makeUpload();
      }
  }

  /**
   * If the width is greater than the height, set the shape to horizontal. If the height is greater
   * than the width, set the shape to vertical. Otherwise, set the shape to square
   * @param {number} width - The width of the image.
   * @param {number} height - The height of the image.
   */
  setShape(width: number, height: number) {
      if (width > height) {
          this.shape = ImageShape.Horizontal;
      } else if (width < height) {
          this.shape = ImageShape.Vertical;
      } else {
          this.shape = ImageShape.Square;
      }
  }

  /**
   * If the response status is between 200 and 300, return the response. Otherwise, return a rejected
   * promise
   * @param {any} response - any
   * @returns A promise.
   */
  status(response: any) {
      if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response)
      } else {
          return Promise.reject(new Error(response.statusText))
      }
  }

  /**
   * It reads the response from Cloudinary and decodes it
   * @param {any} response - The response from the Cloudinary server.
   * @returns The response from Cloudinary is being returned to the next handler.
   */
  progress(response: any) {
      const reader: any = response.body.getReader();
      const decoder = new TextDecoder();
      let decodedValue = "";

      function update() {
          return reader.read().then((result: any) => {
              if (result.done) {
                  // Return final response from Cloudinary to next handler
                  return (JSON.parse(decodedValue));
              }
              const buffer: any = result.value || new Uint8Array(0);
              const options: any = { stream: true };
              decodedValue = decoder.decode(buffer, options);
              // Continue reading response
              return update();
          });
      }
      return update();
  }

  closeModal() {
      this.isVisible = false;
  }

}
