import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class CodeTypeConverter implements JsonCustomConvert<CodeType | undefined> {
    serialize() {}
    deserialize(data: any): CodeType | undefined {
        switch(data){
            case "MASTER":
                return CodeType.MASTER;
            case "B2C":
                return CodeType.B2C;
            case "B2B":
                return CodeType.B2B;
            case "PICKUP":
                return CodeType.PICKUP;
            case "MOBILE":
                return CodeType.MOBILE;
            case "EXTERNAL":
                return CodeType.EXTERNAL;
            default:
                return undefined;
        }
    }
}

/**
 * Code types
 * - MASTER
 * - B2C
 * - B2B
 * - PICKUP
 * - MOBILE
 */
export enum CodeType {
    MASTER = "MASTER",
    B2C = "B2C",
    B2B = "B2B",
    PICKUP = "PICKUP",
    MOBILE = "MOBILE",
    EXTERNAL = "EXTERNAL"
}