import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Account, AccountConverter } from "./account";
import { Code, CodeArrayConverter } from "./code";
import { Locker } from "./locker";

@JsonConverter
export class WizardLockerArrayConverter implements JsonCustomConvert<Array<WizardLocker>> {
    serialize() { }
    deserialize(data: any): Array<WizardLocker> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, WizardLocker);
    }
}

@JsonObject("WizardLocker")
export class WizardLocker extends Locker {
    @JsonProperty("codes", CodeArrayConverter, false)
	codes: Array<Code> = [];

	constructor(locker?: Locker) {
		super();
		if (locker) {
			this.location = locker.location;
			this.model = locker.model;
			this.name = locker.name;
			this.uuid = locker.uuid;
			this.vendor = locker.vendor;
		}
	}

	dto() {
		return {
			uuid: this.uuid,
			name: this.name,
			vendor: this.vendor,
			model: this.model,
			location: this.location.dto(),
			codes: this.codes.map(c => c.dto())
		}
	}
}

@JsonObject("Wizard")
export class Wizard {
    @JsonProperty("account", String, false)
	account: string = "";
    @JsonProperty("lockers", WizardLockerArrayConverter, false)
	lockers: Array<WizardLocker> = [];

	importLockers(lockers: Array<Locker>) {
		this.lockers = [];		
		lockers.forEach(l => {
			const wizardLocker: WizardLocker = new WizardLocker(l);
			this.lockers.push(wizardLocker);
		});
	}

	dto() {
		return {
			account: this.account,
			lockers: this.lockers.map(l => l.dto())
		}
	}
}