import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowSideMenuService {
	showMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() { }

	update(show: boolean) {
		this.showMenu$.next(show);
	}
}
