import { Component, OnInit } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { Appearance } from 'src/app/core/models/appearance';

@Component({
	selector: 'app-public-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.less']
})
export class PublicBaseComponent implements OnInit {

	constructor(private nzConfigService: NzConfigService) { }
	readonly smartLockerApparence: Appearance = new Appearance();

	ngOnInit(): void {
		this.nzConfigService.set('theme',
			{
				primaryColor: this.smartLockerApparence.primary_color_accent,
				successColor: this.smartLockerApparence.success_color
			});
		const elements: HTMLCollectionOf<Element> = document.getElementsByClassName("public-header");
		for (let i = 0; i < elements.length; i++) {
			elements[i].setAttribute('style', 'background-color: ' + this.smartLockerApparence.tertiary_color_accent);
		}
		// Button text color
		const head = document.head || document.getElementsByTagName('head')[0],
			style: any = document.createElement('style');
		const css: string = `:root {
			--ant-btn-primary-color: #fff;
		}`
		head.appendChild(style);

		style.type = 'text/css';
		if (style.styleSheet) { // This is required for IE8 and below.
			style.styleSheet.cssText = css;
		} else {
			style.appendChild(document.createTextNode(css));
		}
	}

}
