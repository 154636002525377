import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";

@JsonConverter
export class responseConverter implements JsonCustomConvert<Resp>{
	serialize() { }
	deserialize(data: any): Resp {
		const jsonConvert: JsonConvert = new JsonConvert();
		return jsonConvert.deserializeObject(data, Resp);
	}
}

@JsonObject("Resp")
/** Base response */
export class Resp {
	@JsonProperty("success", Boolean, true)
	success: boolean = false;
	@JsonProperty("errorCode", Number, true)
	errorCode: number = 0;
	@JsonProperty("errorMessage", String, true)
	errorMessage: string = "";
	@JsonProperty("elapsed", Number, true)
	elapsed: number = 0;
}
