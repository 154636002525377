import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { Account } from 'src/app/core/models/account';
import { Operation } from 'src/app/core/models/operation';
import { NotificationSystem } from 'src/app/core/models/notification-system';
import { NotificationEnum } from "src/app/core/models/notification.enum";
import { IdentityService } from 'src/app/core/services/identity.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AccountService } from 'src/app/core/services/network/account.service';

@Component({
	selector: 'app-preference-management',
	templateUrl: './preference-management.component.html',
	styleUrls: ['./preference-management.component.less']
})
export class PreferenceManagementComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	events: Array<string> = Object.keys(Operation);
	selectedEvents: Set<string> = new Set();
	notifications: Array<NotificationEnum> = (<Array<NotificationEnum>>Object.keys(NotificationEnum).filter(n => n !== "EMPTY"));
	selectedNotification: NotificationEnum = NotificationEnum.EMPTY;
	selectedNotificationSystem: NotificationSystem = new NotificationSystem();
	selectedCheckboxModel: Array<{ label: string; value: string; checked: boolean; }> = []
	account: Account = new Account();
	subscriptions: Array<Subscription> = [];

	constructor(private translate: TranslateService,
		private identityService: IdentityService,
		private notification: NzNotificationService,
		private accountService: AccountService,
		private loaderService: LoaderService) { }

	ngOnInit(): void {
		this.subscriptions.push(this.identityService.identity$.subscribe({
			next: (identity) => {
				if (identity) {
					this.account = identity;
					if (this.account.notification_settings.email.isEnable()) {
						this.selectedNotification = NotificationEnum.EMAIL;
						this.onChangeSelectNotification();
					}
				}
			},
			error: (err) => {
				console.error(err);
			}
		}));
	}

	/** Update notification settings */
	updateNotificationSettings(): void {
		this.loaderService.update(true);
		this.accountService.updateUser(this.account).subscribe(
			{
				next: (identity) => {
					if (identity && identity.uuid) {
						Helpers.successNotification(this.notification, this.translate, 'account.successes.updateNotification');
						this.identityService.update(identity);
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'account.errors.unableToUpdateNotification');
					}
					this.loaderService.update(false);
				},
				error: (err) => {
					this.loaderService.update(false);
					console.error(err);
					Helpers.errorNotification(this.notification, this.translate, 'account.errors.unableToUpdateNotification');
				}
			}
		)
	}

	/** Get selected notifications values */
	onChangeSelectNotification(): void {
		switch (this.selectedNotification) {
			case NotificationEnum.EMAIL:
				this.selectedNotificationSystem = this.account.notification_settings.email;
				this.populateSelectedCheckboxModel();
				break;
		}
	}

	/** Handle box change event */
	onCheckBoxChange(values: string[]): void {
		this.updateSelectedNotificationSystem(values);
		this.updateAccountNotificationByType();
	}

	/** Bind selected checkbox model */
	populateSelectedCheckboxModel(): void {
		this.selectedCheckboxModel = [];
		this.selectedCheckboxModel.push(
			{
				label: 'serviceStatus.delivery',
				value: 'DELIVERY',
				checked: this.selectedNotificationSystem.delivery
			}
		);
		this.selectedCheckboxModel.push(
			{
				label: 'serviceStatus.exchange',
				value: 'EXCHANGE',
				checked: this.selectedNotificationSystem.exchange
			}
		);
		this.selectedCheckboxModel.push(
			{
				label: 'serviceStatus.return',
				value: 'PICKUP',
				checked: this.selectedNotificationSystem.pickup
			}
		);
		this.selectedCheckboxModel.push(
			{
				label: 'serviceStatus.selfDeposit',
				value: 'SELFDEPOSIT',
				checked: this.selectedNotificationSystem.self_deposit
			}
		);
		// this.selectedCheckboxModel.push(
		// 	{
		// 		label: 'serviceStatus.reservation',
		// 		value: 'RESERVATION',
		// 		checked: this.selectedNotificationSystem.reservation
		// 	}
		// );
		this.selectedCheckboxModel.push(
			{
				label: 'serviceStatus.delegation',
				value: 'DELEGATION',
				checked: this.selectedNotificationSystem.delegation
			}
		);
	}

	/** Updaete values of selected notification system */
	updateSelectedNotificationSystem(values: string[]): void {
		this.selectedCheckboxModel.forEach(cb => {
			cb.checked = values.indexOf(cb.value) > -1;
		});
		this.selectedCheckboxModel.map(item => {
			switch (item.value as Operation) {
				case Operation.DELIVERY:
					this.selectedNotificationSystem.delivery = item.checked;
					break;
				case Operation.EXCHANGE:
					this.selectedNotificationSystem.exchange = item.checked;
					break;
				case Operation.PICKUP:
					this.selectedNotificationSystem.pickup = item.checked;
					break;
				case Operation.SELFDEPOSIT:
					this.selectedNotificationSystem.self_deposit = item.checked;
					break;
				case Operation.RESERVATION:
					this.selectedNotificationSystem.reservation = item.checked;
					break;
				case Operation.DELEGATION:
					this.selectedNotificationSystem.delegation = item.checked;
					break;
			}
		});
	}

	updateAccountNotificationByType(): void {
		switch (this.selectedNotification) {
			case NotificationEnum.EMAIL:
				this.account.notification_settings.email = this.selectedNotificationSystem;
				break;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
		this.identityService.reset();
	}

}
