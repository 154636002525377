import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { Subscription } from 'rxjs';
import { it_IT } from './core/config/i18n-ng-zorro/it_IT';
import { locale as enLang } from './core/config/i18n/en';
import { locale as itLang } from './core/config/i18n/it';
import { Helpers } from './core/helpers';
import { LanguageService } from './core/services/language.service';
import { TranslationService } from './core/services/translation.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
	subscriptions: Array<Subscription> = [];
	title = '';

	constructor(private translationService: TranslationService, private chRef: ChangeDetectorRef, private i18n: NzI18nService, 
		private languageService: LanguageService) {
        let userLang = navigator.language.substring(0, 2).toLowerCase();
		// Set user's browser language if it's the first vist
		if (!Helpers.getLanguage()) {
			Helpers.setLanguage(userLang);
		} else {
			userLang = Helpers.getLanguage() || "en";
		}
		// Register translations
		this.translationService.loadTranslations(enLang, itLang);
        // Set the language globally
		this.translationService.setLanguage(userLang);
		this.i18n.setLocale(userLang === 'it' ? it_IT : en_US);
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.languageService.language$.subscribe(
				{
					next: (lang) =>  {
						this.i18n.setLocale(lang === 'it' ? it_IT : en_US);
					},
					error: (err) => {
						console.error(err);
					}
				}
			)
		);
	}

    ngAfterViewChecked() {
        // Register translations
        this.translationService.loadTranslations(enLang, itLang);
        this.chRef.detectChanges();
    }

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
