import { Menu } from "../models/menu";

/** Creating an array of objects that will be used to create the sidebar menu. */
export const MenuConfig: Array<Menu> = [
	{
		icon: 'home',
		translate: 'menu.home',
		link: '/home',
		level: 1
	},
	{
		icon: 'user',
		translate: 'menu.account',
		link: '/account',
		level: 1
	},
	{
		icon: 'setting',
		translate: 'menu.lockers',
		link: '/lockers',
		level: 1
	}
];