<p>{{'wizard.lockerSteps.autoGenerationPin' | translate }}</p>

<div *ngIf="!loading">
    <ng-container *ngIf="masterCode && deliveryCode; else error">
        <p>{{'pinManagement.yourPins' | translate}}</p>
        <!-- Master -->
        <p class="fw-bold mb-0" *ngIf="isSupporterdCode(codeType.MASTER)">
            <span><img style="width: 24px;" src="assets/images/key.png"></span>
            {{'pinManagement.master' | translate}}: <span>{{masterCode.code}}</span></p>
        <p class="text-muted">{{'pinManagement.masterPinDescription' | translate}}</p>
        <!-- Delivery -->
        <p class="fw-bold mb-0" *ngIf="isSupporterdCode(codeType.B2C) && deliveryCode.locker_uuid">
            <span><img style="width: 24px;" src="assets/images/outline_delivery.png"></span>
            {{'pinManagement.delivery' | translate}}: <span>{{deliveryCode.code}}</span></p>
        <p class="text-muted">{{'pinManagement.deliveriesPinDescription' | translate}}</p>
        <!-- Return -->
        <p class="fw-bold mb-0" *ngIf="isSupporterdCode(codeType.B2C) && returnCode.locker_uuid">
            <span><img style="width: 24px;" src="assets/images/settings_backup_restore.png"></span>
            {{'pinManagement.pickup' | translate}}: <span>{{returnCode.code}}</span></p>
        <p class="text-muted">{{'pinManagement.returnPinDescription' | translate}}</p>
        <!-- External -->
        <p class="fw-bold mb-0" *ngIf="isSupporterdCode(codeType.EXTERNAL)">
            <span><img style="width: 24px;" src="assets/images/external.png"></span>
            {{'pinManagement.external' | translate}}: <span>{{externalCode.code}}</span></p>
        <p class="text-muted">{{'pinManagement.externalPinDescription' | translate}}</p>
        <!-- Other codes -->
        <br *ngIf="otherCodes.length === 0">
        <ng-container *ngIf="otherCodes.length > 0">
            <p class="mb-0">{{'pinManagement.yourOldPinsTitle' | translate}}</p>
            <p>{{'pinManagement.yourOldPinsDescription' | translate}}</p>
            <div *ngFor="let code of otherCodes" class="mb-2">
                <p class="fw-bold mb-0">
                    <span><img style="width: 24px;" src="assets/images/layers.png"></span>
                    {{code.code ? code.code : 'pinManagement.noPin' | translate}}</p>
                <p class="text-muted">{{code.description ? code.description : 'pinManagement.noDescription' | translate}}</p>
            </div>
            <br>
        </ng-container>
        <p>{{'wizard.lockerSteps.manageYourPins' | translate}}</p>
    </ng-container>
    <ng-template #error>
        <p>{{'pinManagement.errors.unableToGetCodes' | translate}}</p>
    </ng-template>
</div>
<div  *ngIf="loading" class="p-5">
    <nz-spin nzTip="{{'pinManagement.pinCreationLoading' | translate}}"></nz-spin>
</div>

