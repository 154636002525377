import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { BoxArrayConverter, Box } from "./box";
import { Resp } from "./resp";


@JsonConverter
export class FreeBoxArrayConverter implements JsonCustomConvert<Array<FreeBox>> {
    serialize() { }
    deserialize(data: any): Array<FreeBox> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, FreeBox);
    }
}

@JsonObject("FreeBox")
export class FreeBox {
    @JsonProperty("locker_uuid", String, true)
    locker_uuid: string = "";
    @JsonProperty("boxes", BoxArrayConverter, true)
    boxes: Array<Box> = [];
}

@JsonObject("FreeBoxData")
export class FreeBoxData extends Resp {
    @JsonProperty("data", FreeBoxArrayConverter, true)
    data: Array<FreeBox> = [];
}
