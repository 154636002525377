import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthBaseComponent } from './pages/auth/auth-base/auth-base.component';
import { DashboardBaseComponent } from './pages/dashboard/dashboard-base/dashboard-base.component';
import { PageNotFoundComponent } from './pages/dashboard/page-not-found/page-not-found.component';
import { PublicBaseComponent } from './pages/public/base/base.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'account'
	},
	// {
	// 	path: 'public',
	// 	component: PublicBaseComponent,
	// 	children: [
	// 		{ path: 'email-verified', loadChildren: () => import('./pages/public/email/email-verified/email-verified.module').then(m => m.EmailVerifiedModule) },
	// 		{ path: 'email-not-found', loadChildren: () => import('./pages/public/email/email-not-found/email-not-found.module').then(m => m.EmailNotFoundModule) },
	// 		{ path: 'email-error', loadChildren: () => import('./pages/public/email/email-error/email-error.module').then(m => m.EmailErrorModule) },
	// 	]
	// },
	{
		path: 'auth',
		component: AuthBaseComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: '/auth/login' },
			{ path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule) },
			{ path: 'forgot-password', loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
			{ path: 'first-access', loadChildren: () => import('./pages/auth/first-access/first-access.module').then(m => m.FirstAccessModule) }
		]
	},
	{
		path: '',
		component: DashboardBaseComponent,
		children: [
			{ path: 'unauthorized', loadChildren: () => import('./pages/dashboard/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule), canActivate: [AuthGuard] },
			{ path: 'home', loadChildren: () => import('./pages/dashboard/home/home.module').then(m => m.HomeModule), data: {}, canActivate: [AuthGuard] },
			{ path: 'account', loadChildren: () => import('./pages/dashboard/account/account.module').then(m => m.AccountModule), data: {}, canActivate: [AuthGuard] },
			{ path: 'lockers', loadChildren: () => import('./pages/dashboard/lockers/lockers.module').then(m => m.LockersModule), data: {}, canActivate: [AuthGuard] },
			{ path: 'wizard', loadChildren: () => import('./pages/dashboard/wizard/wizard.module').then(m => m.WizardModule), data: {}, canActivate: [AuthGuard] }
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'page-not-found',
		component: DashboardBaseComponent,
		children: [{
			path: '',
			component: PageNotFoundComponent
		}],
		canActivate: [AuthGuard]
	}, {
		path: '**',
		pathMatch: 'full',
		redirectTo: 'page-not-found'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
