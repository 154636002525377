import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

@JsonConverter
export class AddressConverter implements JsonCustomConvert<Address> {
    serialize() {}
    deserialize(data: any): Address {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Address);
    }
}

@JsonObject("Address")
export class Address {
    @JsonProperty("address", String, true)
	address: string = "";
    @JsonProperty("city", String, true)
	city: string = "";
    @JsonProperty("province", String, true)
	province: string = "";
    @JsonProperty("region", String, true)
	region: string = "";
    @JsonProperty("country", String, true)
	country: string = "";
    @JsonProperty("zip_code", String, true)
	zip_code: string = "";

    dto() {
        return {
            address: this.address,
            city: this.city,
            province: this.province,
            region: this.region,
            country: this.country,
            zip_code: this.zip_code
        }
    }
}