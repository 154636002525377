import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { Code } from 'src/app/core/models/code';
import { deliveryDescription, externalDescription, returnDescription } from 'src/app/core/models/code-descriptions';
import { CodeGenerateRequest } from 'src/app/core/models/code-generate-request';
import { CodeType } from 'src/app/core/models/code-type';
import { Locker } from 'src/app/core/models/locker';
import { ReservationType } from 'src/app/core/models/reservation-type';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-pin-auto',
	templateUrl: './pin-auto.component.html',
	styleUrls: ['./pin-auto.component.less']
})
export class PinAutoComponent implements OnInit, OnDestroy {
	@Input() locker: Locker = new Locker();
	@Output() lockerCodes = new EventEmitter<{ lockerUuid: string, codes: Array<Code> }>();
	subscriptions: Array<Subscription> = [];
	@Input() supportedPins: Array<CodeType> = [
		CodeType.MASTER,
		CodeType.B2C,
		CodeType.B2B,
		CodeType.PICKUP,
		CodeType.MOBILE,
		CodeType.EXTERNAL,
	];
	loading: boolean = false;
	masterCode!: Code;
	deliveryCode!: Code;
	returnCode!: Code;
	mobileCode!: Code;
	externalCode!: Code;
	otherCodes: Array<Code> = [];
	// deliveryB2BCodes: Array<Code> = [];
	// deliveryB2CCodes: Array<Code> = [];
	// pickupCode!: Code;
	codeType = CodeType;

	constructor(private lockerService: LockerService,
		private router: Router,
		private translate: TranslateService,
		private notification: NzNotificationService) { }

	ngOnInit(): void {
		this.loading = true;
		this.getCodes();
	}

	/** Get account's saved codes */
	getCodes(): void {
		this.loading = true;
		this.subscriptions.push(
			this.lockerService.getCodes(this.locker.uuid).subscribe(
				{
					next: (resp) => {
						if (resp) {
							// Get other codes
							this.otherCodes = resp.filter(code => code.type !== CodeType.MASTER && 
								code.type !== CodeType.MOBILE && code.type !== CodeType.EXTERNAL &&
								code.description !== deliveryDescription &&
								code.description !== returnDescription );
							// Get master code
							const masterCode = resp.find(c => c.type == CodeType.MASTER);
							if (masterCode) {
								this.masterCode = masterCode;
							} else { // If master code not exists, create code
								this.generateCode(CodeType.MASTER);
							}
							// Get B2C codes (return and delivery)
							if (this.isSupporterdCode(CodeType.B2C)) {
								if (resp.some(c => c.type == CodeType.B2C)) {
									const b2bCodes = resp.filter(c => c.type == CodeType.B2C);
									if (b2bCodes.length > 0) {
										if (b2bCodes.some(code => code.description === deliveryDescription)) {
											this.deliveryCode = b2bCodes.filter(code => code.description === deliveryDescription)[0];
										} else { // If delivery code not exists, create code
											this.generateCode(CodeType.B2C, ReservationType.DELIVERY);
										}
										if (b2bCodes.some(code => code.description === returnDescription)) {
											this.returnCode = b2bCodes.filter(code => code.description === returnDescription)[0];
										} else { // If retunr code not exists, create code
											this.generateCode(CodeType.B2C, ReservationType.RETURN);
										}
									} else { // If delivery and return codes not exist, create codes
										this.generateCode(CodeType.B2C, ReservationType.DELIVERY);
										this.generateCode(CodeType.B2C, ReservationType.RETURN);
									}
								} else { // If delivery and return code not exist, create codes
									this.generateCode(CodeType.B2C, ReservationType.DELIVERY);
									this.generateCode(CodeType.B2C, ReservationType.RETURN);
								}
							}

							// Get mobile code
							if (this.isSupporterdCode(CodeType.MOBILE)) {
								const mobileCode = resp.find(c => c.type == CodeType.MOBILE);
								if (!mobileCode) { // If mobile code not exists, create code
									this.generateCode(CodeType.MOBILE);
								} else {
									this.mobileCode = mobileCode;
								}
							}

							// Get external code
							if (this.isSupporterdCode(CodeType.EXTERNAL)) {
								const extCode = resp.find(c => c.type === CodeType.EXTERNAL);
								if (!extCode) { // If external code not exists, create code
									this.generateCode(CodeType.EXTERNAL);
								} else {
									this.externalCode = extCode;
								}
							}
						} else { // If no code exists, create codes for the supported types
							this.generateCode(CodeType.MASTER);
							if (this.isSupporterdCode(this.codeType.B2C)) this.generateCode(CodeType.B2C, ReservationType.DELIVERY);
							if (this.isSupporterdCode(this.codeType.B2C)) this.generateCode(CodeType.B2C, ReservationType.RETURN);
							if (this.isSupporterdCode(this.codeType.MOBILE)) this.generateCode(CodeType.MOBILE);
							if (this.isSupporterdCode(this.codeType.EXTERNAL)) this.generateCode(CodeType.EXTERNAL);
						}
						this.codesChange();
						this.loading = false;
					},
					error: (err) => {
						console.error(err);
						this.loading = false;
						if (err.status === 403) {
							Helpers.goToUnauthorized(this.router);
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGetCodes');
						}
					}
				}
			)
		);
	}

	/**
	 * Generate new code
	 * @param type code type
	 * @param actionType B2C code type
	 */
	generateCode(type: CodeType, actionType: ReservationType = ReservationType.EMPTY): void {
		this.loading = true;
		const body: CodeGenerateRequest = new CodeGenerateRequest();
		body.type = type;
		this.subscriptions.push(
			this.lockerService.generateCode(this.locker.uuid, body, actionType).subscribe(
				{
					next: (resp) => {
						if (resp) {
							switch (resp.type) {
								case CodeType.MASTER:
									this.masterCode = resp;
									break;
								case CodeType.B2B:
								case CodeType.B2C:
									if (resp.description === deliveryDescription) {
										this.deliveryCode = resp;
									} else if (resp.description === returnDescription) {
										this.returnCode = resp;
									}
									break;
								case CodeType.MOBILE:
									this.externalCode = resp;
									break;
								case CodeType.EXTERNAL:
									this.externalCode = resp;
									break;
								default:
									Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGenerateCode');
									break;
							}
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGenerateCode');
						}
						this.codesChange();
						this.loading = false;
					},
					error: (err) => {
						console.error(err);
						this.loading = false;
						if (err.status === 403) {
							Helpers.goToUnauthorized(this.router);
						} else {
							Helpers.errorNotification(this.notification, this.translate, 'pinManagement.errors.unableToGenerateCode');
						}
					}
				}
			)
		);
	}

	ngOnDestroy(): void {
		this.codesChange();
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	codesChange() {
		const codes: Array<Code> = [];
		if (this.masterCode) {
			codes.push(this.masterCode);
		} 
		if (this.deliveryCode) {
			codes.push(this.deliveryCode);
		}
		if (this.returnCode) {
			codes.push(this.returnCode);
		}
		if (this.mobileCode) {
			codes.push(this.mobileCode);
		}
		if (this.externalCode) {
			codes.push(this.externalCode);
		}
		codes.sort((a, b) => a.vendor_token_uuid.localeCompare(b.vendor_token_uuid));
		this.lockerCodes.emit({ lockerUuid: this.locker.uuid, codes: codes });
	}

	isSupporterdCode(code: CodeType) {
		return this.supportedPins.some(p => p === code);
	}

}
