import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { JsonConvert } from "json2typescript";
import * as moment from "moment";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Account } from "../models/account";
import { Appearance } from "../models/appearance";
import { Wizard } from "../models/wizard";

export class Helpers {
	private static readonly _urlRegEx = /^(http|https):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
	/** URL regular expression */
	public static get urlRegEx() {
		return Helpers._urlRegEx;
	}
	private static readonly _emailRegExp: RegExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	/** Email regular expression */
	public static get emailRegExp(): RegExp {
		return Helpers._emailRegExp;
	}

	/**
	 * Remove all the items in the local storage
	 */
	static cleanStorage() {
		const config: any = this.getConfig();
		delete config['fu'];
		delete config['session-expire'];
		delete config['identity'];
		delete config['apiKey'];
		delete config['appearance'];
		this.setConfig(config);
	}

	/** Save configuration to local storage */
	static setConfig(config: any) {
		localStorage.setItem('sl-user-config', JSON.stringify(config));
	}

	/** Get configuration object from local storage */
	static getConfig(): any {
		return localStorage.getItem('sl-user-config') ? JSON.parse(localStorage.getItem('sl-user-config')!) : {};
	}

	static setItPrivacyAndTCLinks(privacyLink: string, tcLink: string) {
		let privacyAndTC: any = { privacy_link: privacyLink, tc_link: tcLink };
		localStorage.setItem('privacy-tc-links-it', JSON.stringify(privacyAndTC));
	}

	static setEnPrivacyAndTCLinks(privacyLink: string, tcLink: string) {
		let privacyAndTC: any = { privacy_link: privacyLink, tc_link: tcLink };
		localStorage.setItem('privacy-tc-links-en', JSON.stringify(privacyAndTC));
	}

	static getItPrivacyAndTCLinks(): any {
		return localStorage.getItem('privacy-tc-links-it') ? JSON.parse(localStorage.getItem('privacy-tc-links-it')!) : {};
	}

	static getEnPrivacyAndTCLinks(): any {
		return localStorage.getItem('privacy-tc-links-en') ? JSON.parse(localStorage.getItem('privacy-tc-links-en')!) : {};
	}

	/** Save firebase user in local storage  */
	static setFirebaseUser(fu: any) {
		const config: any = this.getConfig();
		config['fu'] = fu;
		this.setConfig(config);
	}

	/** Get firebase user from local storage  */
	static getFirebaseUser() {
		const config: any = this.getConfig();
		return config['fu'] || null;
	}

	/** Save session expire in local storage  */
	static setSessionExpiration() {
		const config: any = this.getConfig();
		const expire: any = moment().add(12, 'hours').unix();
		config['session-expire'] = expire;
		this.setConfig(config);
	}

	/** Get session expire from local storage  */
	static getSessionExpire() {
		const config: any = this.getConfig();
		if (config['session-expire']) {
			return moment.unix(Number(config['session-expire']));
		} else {
			return moment().subtract(10, 'years');
		}
	}

	/** Save email in local storage in a list of emails */
	static setEmail(email?: string | null) {
		if (email) {
			const emails: Array<string> = this.getEmails();
			if (emails.indexOf(email) == -1) {
				emails.push(email);
			}
			localStorage.setItem('emails', JSON.stringify(emails));
		}
	}

	/** Get emails from local storage */
	static getEmails(): Array<string> {
		const emails: Array<string> = localStorage.getItem('emails') ? JSON.parse(localStorage.getItem('emails')!) : [];
		return emails;
	}

	/**
	 * Save user initialized his/her data with wizard in local storage
	 */
	static initializeUserData(wizard: Wizard) {
		const config: any = this.getConfig();
		wizard.lockers.sort((a, b) => a.uuid.localeCompare(b.uuid));
		wizard.lockers.forEach(l => {
			l.codes.sort((a, b) => a.vendor_token_uuid.localeCompare(b.vendor_token_uuid));
		});
		config['initializedUser'] = wizard.dto();
		this.setConfig(config);
	}

	/** Get wizard data from local storage */
	static getWizardData(): Wizard {
		const config: any = this.getConfig();
		if (config) {
			const stored: string | null = config['initializedUser'];
			if (stored) {
				const jsonConvert: JsonConvert = new JsonConvert();
				const wizard: Wizard = jsonConvert.deserializeObject(stored, Wizard);
				return wizard;
			} else {
				return new Wizard();
			}
		} else {
			return new Wizard();
		}
	}

	/** Save identity in local storage  */
	static setIdenity(identity: Account | null) {
		const config: any = this.getConfig();
		config['identity'] = identity ? identity?.dto(true, true) : null;
		this.setConfig(config);
	}

	/** Get identity from local storage  */
	static getIdentity() {
		const config: any = this.getConfig();
		if (config && config['identity']) {
			const jsonConvert: JsonConvert = new JsonConvert();
			const identity: Account = jsonConvert.deserializeObject(config['identity'], Account);
			return identity || null;
		} else {
			return null;
		}
	}

	/** Save api key in local storage  */
	static setApiKey(apiKey: string) {
		const config: any = this.getConfig();
		config['apiKey'] = apiKey;
		this.setConfig(config);
	}

	/** Get api key from local storage  */
	static getApiKey() {
		const config: any = this.getConfig();
		return config['apiKey'] || null;
	}

	/** Save appearance in local storage  */
	static setDashboardConfig(appearance: any) {
		const config: any = this.getConfig();
		config['appearance'] = appearance;
		this.setConfig(config);
	}

	/** Get appearance from local storage  */
	static getDashboardConfig() {
		const config: any = this.getConfig();
		if (config['appearance']) {
			const jsonConvert: JsonConvert = new JsonConvert();
			const dashboardConfig: Appearance = jsonConvert.deserializeObject(config['appearance'], Appearance);
			return dashboardConfig || null;
		} else {
			return null;
		}
	}

	/** Save current language in local storage */
	static setLanguage(userLang: string) {
		return localStorage.setItem('language', userLang);
	}

	/** Get current language */
	static getLanguage() {
		return localStorage.getItem('language') || navigator.language.substring(0, 2).toLowerCase();
	}

	/** Clear styles customization from dom */
	static clearCustomization(): void {
		document.getElementById('customRootVariable')?.remove();
	}

	/**
	 * Build filter querystring
	 * @param filter Array of dates
	 * @returns 
	 */
	static buildQs(filter: Array<Date>) {
		if (filter.length === 2) {
			const start = moment(filter[0].getTime()).format('YYYY-MM-DD');
			const end = moment(filter[1].getTime()).format('YYYY-MM-DD');
			return `since=${start}&until=${end}`;
		} else {
			return '';
		}
	}

	/** Check if login is in progress */
	static isLogin() {
		const isLogin = localStorage.getItem('isLogin');
		if (isLogin) {
			return true;
		} else {
			return false;
		}
	}

	/** Check if it's logout process */
	static isLogout() {
		const isLogout = localStorage.getItem('isLogout');
		return isLogout && isLogout === "1";
	}

	/** Get date picker date format by current language */
	static get datePickerDateFormat(): string {
		if (this.getLanguage() === 'it') {
			return 'dd-MM-YYYY';
		} else if (this.getLanguage() === 'en') {
			return 'MM-dd-YYYY';
		} else {
			return 'YYYY-MM-dd';
		}
	}

	/** Show error notification */
	static errorNotification(notification: NzNotificationService, translate: TranslateService, message: string): void {
		notification.create(
			'error',
			translate.instant('general.error'),
			translate.instant(message)
		);
	}

	/** Show success notification */
	static successNotification(notification: NzNotificationService, translate: TranslateService, message: string, title?: string): void {
		let successTitle = title ? translate.instant(title) : '';
		notification.create(
			'success',
			successTitle,
			translate.instant(message)
		);
	}

	/** Navigate to unauthorized */
	static goToUnauthorized(router: Router): void {
		router.navigateByUrl('/unauthorized');
	}

	/**
	 * Check if passed url is valid
	 * @param url 
	 * @returns 
	 */
	static validateUrl(url: string) {
		const isValid: boolean = this.urlRegEx.test(url.trim());
		return isValid;
	}

	/**
	 * Check if user initialied his/her data with wizard
	 * @returns 
	 */
	static isInitializedUserData(wizard: Wizard) {
		// Fixed: Use getWizardData() directly without JSON.stringify for null check
		const storedData = this.getWizardData();
		if (!storedData) {
			return false;
		}
		const stored: string = JSON.stringify(storedData);

		// Sort lockers by uuid
		wizard.lockers.sort((a, b) => a.uuid.localeCompare(b.uuid));

		// Sort locker codes by vendor_token_uuid within each locker
		wizard.lockers.forEach(l => {
			l.codes.sort((a, b) => a.vendor_token_uuid.localeCompare(b.vendor_token_uuid));
		});

		const passedData: string = JSON.stringify(wizard.dto());
		return passedData === stored;
	}
}
