import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import 'firebase/compact/auth';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Helpers } from '../helpers';

@Injectable({
	providedIn: 'root'
})
export class AuthFirebaseService {

	constructor(public router: Router) { }

	/** Check if user has a valid session */
	alreadyLoggedin() {
		return new Observable<boolean>((observer) => {
			const auth = getAuth();
			onAuthStateChanged(auth, user => {
				if (user) {
					Helpers.setFirebaseUser(user.toJSON());
					Helpers.setEmail(user.email);
					const userLocal = Helpers.getFirebaseUser();
					const expire = moment(userLocal.stsTokenManager.expirationTime);
					const now = moment();
					// Check token expiration
					if (expire.isAfter(now)) {
						return observer.next(true);
					} else {
						// If session is expired since 12 hours, refresh token and save it in localstorage
						const sessionExpire = Helpers.getSessionExpire();
						// Check session expire value
						if (sessionExpire.isAfter(now)) {
							// Update user
							user.reload().then(() => {
								const currentUser = auth.currentUser;
								if (currentUser) {
									Helpers.setFirebaseUser(currentUser.toJSON());
									return observer.next(true);
								} else {
									return observer.next(false);
								}
							});
						} else { // Session is expired more than 12 hours ago, logout
							Helpers.cleanStorage();
							return observer.next(false);
						}
					}
				} else {
					return observer.next(false);
				}
			});
		});
	}

	async logout() {
		const auth = getAuth();
		return await auth.signOut();
	}

	async login(email: string, password: string) {
		const auth = getAuth();
		return await signInWithEmailAndPassword(auth, email.trim(), password);
	}

	async sendPasswordResetEmail(passwordResetEmail: string) {
		const auth = getAuth();
		return await sendPasswordResetEmail(auth, passwordResetEmail.trim());
	}
}
