import { Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Helpers } from ".";

/** Errors option type */
export type ErrorsOptions = { it: string; en: string } & Record<string, NzSafeAny>;
/** Custom calidation errors type */
export type CustomValidationErrors = Record<string, ErrorsOptions>;

/** Cusom validators class */
export class CustomValidators extends Validators {

	/** Define a custom email validator */
	static emailValidator(): ValidatorFn {
		return (control: AbstractControl): CustomValidationErrors | null => {
			if (control.value) {
				const isEmail = Helpers.emailRegExp.test(control.value.trim());
				return isEmail ? null : { emailValidator: { it: 'Inserire un\'email valida', en: 'Please input a valid email' } };
			} else {
				return null;
			}
		}
	}

	/** Define a custom required validator */
	static required(): ValidatorFn {
		return (control: AbstractControl): CustomValidationErrors | null => {
			if (Validators.required(control) === null) {
				return null;
			}
			return { required: { it: 'Il campo è obbligatorio', en: `Required field` } };
		};
	}

	/** Define a custom min length validator */
	static minLength(minLength: number): ValidatorFn {
		return (control: AbstractControl): CustomValidationErrors | null => {
			if (Validators.minLength(minLength)(control) === null) {
				return null;
			}
			return { minlength: { it: `La lunghezza minima è ${minLength}`, en: `Min length is ${minLength}` } };
		};
	}

	/** Define a custom max length validator */
	static maxLength(maxLength: number): ValidatorFn {
		return (control: AbstractControl): CustomValidationErrors | null => {
			if (Validators.maxLength(maxLength)(control) === null) {
				return null;
			}
			return { maxlength: { it: `La lunghezza massima è ${maxLength}`, en: `Max length is ${maxLength}` } };
		};
	}
}