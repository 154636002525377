import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { NotificationSystem, NotificationSystemConverter } from "./notification-system";

@JsonConverter
export class NotificationSettingsConverter implements JsonCustomConvert<NotificationSettings> {
    serialize() {}
    deserialize(data: any): NotificationSettings {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, NotificationSettings);
    }
}

@JsonObject("NotificationSettings")
export class NotificationSettings {
    @JsonProperty("email", NotificationSystemConverter, true)
    email: NotificationSystem = new NotificationSystem();
    @JsonProperty("push", NotificationSystemConverter, true)
    push: NotificationSystem = new NotificationSystem();

    dto() {
        return {
            email: this.email.dto(),
            push: this.push.dto()
        };
    }
}